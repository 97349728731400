import React, { useState, useEffect } from "react";
import { Card, Button, Tooltip } from "antd";
import { CheckCircleTwoTone, WarningTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import {
  mbwayAboveLimit,
  IsNullUndefinedEmpty,
  paymentMethodsArray,
  getMethodImage,
  haveInstallments,
  paymentMethodValid,
} from "../../../utils/helper";
import PaymentMethod from "../../Shared/PaymentMethod";
import PaymentMethodV2 from "../../Shared/PaymentMethod_v2";
import useMediaQuery from "use-media-antd-query";

const GetIconStatus = ({ selectedPaymentMethod }) => {
  if (IsNullUndefinedEmpty(selectedPaymentMethod)) {
    return <WarningTwoTone twoToneColor={"#d9a02e"} />;
  } else {
    return <CheckCircleTwoTone twoToneColor={"#52c41a"} />;
  }
};

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/
const SelectPaymentMethod = ({ data, actionHandler }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(data?.payment_method?.method_id);

  const [hidePaymentMethods, setHidePaymentMethods] = useState(false);
  const validatePaymentMethod = (method_id = undefined) => paymentMethodValid(data, method_id);

  const colSize = useMediaQuery();
  // console.log("colSize", colSize); // "xs" | "sm" | "md" | "lg" | "xl" | "xxl"

  useEffect(() => {
    //console.log("SelectPaymentMethod", data.payment_method);
    UpdatePanel(data);
  }, [data]);

  const UpdatePanel = (_data) => {
    // if (IsNullUndefinedEmpty(_data["payment_method"]["method_id"])) return;
    // setSelectedPaymentMethod(_data.payment_method.method_id);
    if (!IsNullUndefinedEmpty(selectedPaymentMethod)) {
      setHidePaymentMethods(true);
    }
  };

  const getMethodData = (method_id) => paymentMethodsArray.find((method) => method.method_id === method_id);

  const SelectedMethod = () => {
    if (IsNullUndefinedEmpty(selectedPaymentMethod)) return <></>;
    const method = getMethodData(selectedPaymentMethod);
    return (
      <div className="right">
        <Button type="link">
          <InfoCircleTwoTone /> Alterar
        </Button>
        <img className="price-method-image" height={30} src={getMethodImage(method.method_id)} alt={method.name} />
      </div>
    );
  };

  const ItemIcon = () => {
    const c = validatePaymentMethod();
    if (!c.status) {
      return (
        <Tooltip title={c.data}>
          <WarningTwoTone twoToneColor={"#d9a02e"} />
        </Tooltip>
      );
    }

    return <GetIconStatus selectedPaymentMethod={selectedPaymentMethod} />;
  };

  return (
    <div className={`payment-method-container mt-20 mb-10 ${selectedPaymentMethod === undefined ? "pulse" : ""}`}>
      {/* {!hidePaymentMethods && (
        <> */}
      <PaymentMethodV2
        colSize={colSize}
        data={data}
        actionHandler={actionHandler}
        selected={(method_id) => {
          setSelectedPaymentMethod(method_id);
          setTimeout(() => {
            setHidePaymentMethods(true);
          }, 500);
        }}
      />
    </div>
  );
};

export default SelectPaymentMethod;
