import React, { useState, useEffect } from "react";
import { Spin, Alert } from "antd";
import { useParams } from "react-router-dom";
import { attendee_ticket_get } from "../../api/attendees";
import moment from "moment-timezone";
import QRCode from "react-qr-code";
import eventDefaultImage from "../../assets/images/event.jpg";
// https://codepen.io/anniedotexe/pen/ExmGvOM

const AttendeeTicket = () => {
  let { ticket_code } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [attendeeData, setAttendeeData] = useState(null);
  const [productData, setProductData] = useState(null);
  useEffect(() => {
    loadData(ticket_code);
  }, []);

  const loadData = async (ticket_code) => {
    setIsLoading(true);

    const attendeeResponse = await dbGetAttendeeTicket(ticket_code);

    if (!attendeeResponse.status) {
      setIsLoading(false);
      setDataLoaded(true);
      return;
    }

    console.log(attendeeResponse.data);

    setAttendeeData(attendeeResponse?.data?.attendee);
    setProductData(attendeeResponse?.data?.product);
    setIsLoading(false);
    setDataLoaded(true);
  };

  const dbGetAttendeeTicket = async (ticket_code) => {
    return new Promise((resolve) => {
      try {
        attendee_ticket_get(ticket_code)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  if (dataLoaded && (attendeeData === null || productData === null)) {
    return (
      <div className="attendee-ticket-container">
        <Alert
          message="Oops!"
          description="Ocorreu um erro ao carregar o bilhete. Tente novamente mais tarde."
          type="error"
          showIcon
        />
      </div>
    );
  }

  const EventStartDate = ({ product }) => {
    if (!product) return null;

    const eventContent =
      product?.format === "online-event" ? product?.online_event_content : product?.physical_event_content;

    if (!eventContent) return null;

    let start_date = moment(eventContent?.start_date);
    let end_date = moment(eventContent?.end_date);

    return (
      <div class="time">
        <p>
          {start_date.format("DD-MM-YYYY")} <span> ÀS </span> {start_date.format("HH:mm")}
        </p>
        <p>
          {end_date.format("DD-MM-YYYY")} <span> ÀS </span> {end_date.format("HH:mm")}
        </p>
        {/* <p>
      DOORS <span>@</span> 7:00 PM
    </p> */}
      </div>
    );
  };

  const EventLocation = ({ product }) => {
    if (!product) return null;

    if (product?.format === "online-event") return null;

    const physicalEventContent = product?.physical_event_content;
    console.log(physicalEventContent);
    if (
      physicalEventContent?.address === null ||
      physicalEventContent?.city === null ||
      physicalEventContent?.postal_code === null
    )
      return null;

    return (
      <p class="location">
        <span>{physicalEventContent?.address}</span>
        <span>{physicalEventContent?.city}</span>
        <span>{physicalEventContent?.postal_code}</span>
      </p>
    );
  };

  const eventImage = () => {
    if (productData?.information?.image) {
      return productData?.information?.image;
    }
    return eventDefaultImage;
  };

  return (
    <Spin spinning={isLoading}>
      <div className="attendee-ticket-container">
        <div class="ticket">
          <div class="left">
            <div
              class="image"
              style={{
                backgroundImage: `url(${eventImage()})`,
              }}
            >
              <p class="admit-one">
                <span>SALESPARK</span>
                <span>SALESPARK</span>
                <span>SALESPARK</span>
              </p>
              <div class="ticket-code">
                <p>{attendeeData?.ticket_code}</p>
              </div>
            </div>
            <div class="ticket-info">
              {/* <span>BILHETE ONLINE</span> */}

              <div class="show-name">
                <h1>{productData?.information?.name}</h1>
                <h2>{attendeeData?.name}</h2>
              </div>
              <EventStartDate product={productData} />
              <EventLocation product={productData} />
            </div>
          </div>
          <div class="right">
            <p class="admit-one">
              <span>SALESPARK</span>
              <span>SALESPARK</span>
              <span>SALESPARK</span>
            </p>
            <div class="right-info-container">
              <div class="show-name">
                <small>{attendeeData?.name}</small>
              </div>
              {/* <div class="time">
              <p>
                8:00 PM <span>TO</span> 11:00 PM
              </p>
              <p>
                DOORS <span>@</span> 7:00 PM
              </p>
            </div> */}
              <div class="barcode">
                <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                  {attendeeData?.ticket_code && (
                    <QRCode
                      size={150}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={attendeeData?.ticket_code}
                      // viewBox={`0 0 256 256`}
                    />
                  )}
                </div>
              </div>
              {/* <div class="barcode">
              <img src="https://cdn.britannica.com/57/43857-050-9250A718/bar-code.jpg" alt="QR code" />
            </div> */}
              <p class="ticket-code">{attendeeData?.ticket_code}</p>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AttendeeTicket;
