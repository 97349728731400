import { useState, useEffect } from "react";
import {
  Checkbox,
  Button,
  Result,
  Modal,
  Form,
  Input,
  Select,
  Tag,
  Spin,
  notification,
  Alert,
  Divider,
  Tooltip,
} from "antd";
import { formatCurrency, refundAllowed } from "utils/helper";
import { refundReasons } from "./helper";
import { sales_basic_get, sales_refund_request_post } from "api/sales";
import { validate_iban_post } from "api/validations";
import useMediaQuery from "use-media-antd-query";
import backupCountries from "../../../config/countries.json";
import moment from "moment-timezone";
import { IsNullUndefinedEmpty } from "utils/helper";

const { Option } = Select;
const { confirm } = Modal;

const RefundInfo = ({ data }) => {
  if (data?.refund === true) {
    return <Tag color="red">Reembolsado</Tag>;
  }

  if (data?.refund_requested === true) {
    return <Tag color="volcano">Pedido de reembolso efetuado</Tag>;
  }

  if (data?.dissalow_refund === true) {
    return (
      <Tooltip
        title={`Não é possível efetuar o pedido de reembolso para este produto enquanto não for efetuado o reembolso do(s) seguinte(s) produto(s): ${data?.dissalow_refund_by}`}
      >
        <Tag color="red">Bloqueado por produto complementar</Tag>
      </Tooltip>
    );
  }

  if (data?.disallow_refund_by_service === true) {
    return (
      <Tooltip title={"Não é possível efetuar o pedido de reembolso uma vez que o serviço já foi prestado."}>
        <Tag color="red">Serviço já prestado, não é possível reembolso.</Tag>
      </Tooltip>
    );
  }

  return <></>;
};

const RefundRequest = ({ visible, close, sale, billing, buyer, token }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState({ visible: false, label: "Carregando..." });
  const [sales, setSales] = useState([]);
  const [selectedReason, setSelectedReason] = useState(undefined);
  const [refundConfirmed, setRefundConfirmed] = useState(false);
  const [ibanConfirmed, setIbanConfirmed] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedReasonItem, setSelectedReasonItem] = useState(null);
  const [canRefund, setCanRefund] = useState(true);
  const [ibanInfo, setIbanInfo] = useState(null);
  //const [selectedRefundBy, setSelectedRefundBy] = useState(undefined);
  const colSize = useMediaQuery();

  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = mobileSizes.includes(colSize);

  //  const selectedReasonItem = refundReasons?.find((item) => item?.id === selectedReason);

  useEffect(() => {
    if (visible) {
      setRefundConfirmed(false);
      setIbanConfirmed(false);
      setIbanInfo(null);
      form.resetFields();
      form.setFieldsValue({ confirm: false, confirm_iban: false });
      loadData(sale?.purchase_id);
      setSelectedReasonItem(refundReasons?.find((item) => item?.id === selectedReason));

      return () => {
        setSales([]);
        setRefundConfirmed(false);
        setIbanConfirmed(false);
        setIbanInfo(null);
        setSelectedProduct(null);
        setCanRefund(true);
        setSelectedReasonItem(null);
        form.resetFields();
        form.setFieldsValue({ confirm: false, confirm_iban: false });
      };
    }
  }, [visible]);

  const loadData = async (purchase_id) => {
    setIsLoading({ visible: true, label: "A carregar dados da compra..." });
    const response = await dbGetSaleBasicData(purchase_id, token);
    if (!response.status) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível carregar os dados da compra. Tenta novamente mais tarde.",
      });
      setIsLoading({ visible: false, label: "Aguarda" });
      close();
      return;
    }
    setSales(response.data);
    setIsLoading({ visible: false, label: "Aguarda" });
  };

  const dbPostValidateIBAN = (payload) => {
    return new Promise((resolve) => {
      try {
        validate_iban_post(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbGetSaleBasicData = (purchase_id, token) => {
    return new Promise((resolve) => {
      try {
        sales_basic_get(purchase_id, token)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbPostRefundRequest = (purchase_id, token, data) => {
    return new Promise((resolve) => {
      try {
        sales_refund_request_post(purchase_id, token, data)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const validateIBAN = async (iban) => {
    const response = await dbPostValidateIBAN({ iban: iban });
    if (!response.status) {
      setIbanInfo(null);
      return false;
    }

    setIbanInfo(response.data);
  };

  const onFinish = async (values) => {
    if (values.product === null) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível enviar o pedido de reembolso. Tenta novamente mais tarde.",
      });
      return;
    }

    values.reason = selectedReasonItem.name;

    const productName = sales?.find((item) => item?._id === values?.product)?.product_name;

    if (values?.iban?.length > 0) {
      //values.iban = `PT50 ${values.iban}`;
      const iban_length = values.iban.replace(/_/g, "")?.length;
      if (iban_length < 15) {
        notification.error({
          message: "Oops!",
          description: "O IBAN introduzido não é válido.",
        });
        return;
      }
    }

    // Check if IBAN two first characters are equal to buyer country
    if (values.iban?.toUpperCase().substring(0, 2) !== buyer?.country?.toUpperCase()) {
      const country = backupCountries.find((item) => item.value?.toUpperCase() === buyer?.country?.toUpperCase());
      // notification.error({
      //   message: "Oops!",
      //   description: "O IBAN introduzido não corresponde ao país de origem indicado durante o processo de compra.",
      // });
      Modal.error({
        title: "Oops!",
        content: (
          <>
            <span>O IBAN introduzido não corresponde ao país indicado durante o processo de compra.</span>
            <br />
            <br />
            <strong>
              É necessário indicar um IBAN correspondente ao país da compra{" "}
              {country ? <span>({country.localName})</span> : <></>}.
            </strong>
          </>
        ),
      });

      return;
    }

    const response = await dbPostValidateIBAN({ iban: values.iban });
    if (response.data?.valid === false) {
      notification.error({
        message: "Oops!",
        description: "O IBAN introduzido não é válido.",
      });
      setIbanInfo(response.data);
      return;
    }

    confirm({
      title: "Tens mesmo a certeza?",
      content: (
        <>
          Tens mesmo a certeza que pretendes efetuar o pedido de reembolso para o produto <b>{productName}</b>?
        </>
      ),
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        refundRequestAction(values);
      },
      onCancel() {
        close(false);
      },
    });
  };

  const refundRequestAction = async (values) => {
    setIsLoading({ visible: true, label: "A enviar pedido de reembolso. Aguarda..." });

    // Post refund request
    const response = await dbPostRefundRequest(sale?.purchase_id, token, values);
    // Check if request was successful
    if (!response.status) {
      notification.error({
        message: "Oops!",
        description: "Não foi possível enviar o pedido de reembolso. Tenta novamente mais tarde.",
      });
      setIsLoading({ visible: false, label: "Aguarda" });

      return;
    }

    notification.success({
      message: "Sucesso!",
      description: "O pedido de reembolso foi enviado com sucesso.",
    });

    setIsLoading({ visible: true, label: "Aguarda" });
    form.resetFields();
    close(true);
  };

  const onFinishFailed = (errorInfo) => {
    //  console.log("Failed:", errorInfo);
  };

  const cannotBeRefunded = (item) => {
    if (item?.refund === true) return true;
    if (item?.refund_requested === true) return true;
    if (item?.disallow_refund_by_service === true) return true;
    if (item?.dissalow_refund === true) return true;
    return false;
    //sale?.refund_requested || sale?.dissalow_refund
  };

  const IBANInfo = () => {
    if (ibanInfo === null || ibanInfo === undefined) return <></>;
    if (form.getFieldValue("iban")?.length < 15) return <></>;

    if (ibanInfo?.valid === true) {
      let bankName = "";
      if (ibanInfo?.bankInfo?.bank !== undefined) {
        bankName = <small> ({ibanInfo?.bankInfo?.bank})</small>;
      }
      return (
        <Tag style={{ marginTop: 10 }} color="green">
          IBAN Válido{bankName}
        </Tag>
      );
    }

    return (
      <Tag style={{ marginTop: 10 }} color="red">
        IBAN Inválido
      </Tag>
    );
  };

  const RefundProductInfo = () => {
    setCanRefund(true);
    // Check if selectedProduct exists
    if (IsNullUndefinedEmpty(selectedProduct) === true) return <></>;

    // Get product data
    const product = sales.find((item) => item._id === selectedProduct);

    // Check if product exists
    if (IsNullUndefinedEmpty(product) === true) return <></>;

    // Check if product extra exists
    if (IsNullUndefinedEmpty(product.extra) === true) return <></>;

    let eventData = {};

    if (product.extra.online_event !== undefined) {
      eventData = product.extra.online_event;
    }

    if (product.extra.physical_event !== undefined) {
      eventData = product.extra.physical_event;
    }

    if (IsNullUndefinedEmpty(eventData) === true) {
      return <></>;
    }

    // Check if event has already ended
    if (moment(eventData?.end_date).isBefore(moment())) {
      setCanRefund(false);
      return (
        <Alert
          message="Este produto foi um evento que já terminou."
          description="Não é possível efetuar o pedido de reembolso para um evento que já terminou. Em caso de dúvida entra em contacto com o produtor do produto."
          type="error"
          showIcon
        />
      );
    }

    // All other cases
    return <></>;
  };

  return (
    <>
      <Modal
        width={isMobileSize ? "95%" : "800px"}
        destroyOnClose
        title="Solicitar reembolso"
        visible={visible}
        okButtonProps={{
          form: "frmRefundRequest",
          type: "primary",
          htmlType: "submit",
          key: "modal-submit",
          disabled: canRefund === false,
        }}
        okText="Confirmar"
        cancelText="Cancelar"
        onCancel={close}
      >
        <Spin spinning={isLoading.visible} tip={isLoading.label}>
          <RefundProductInfo />
          <Form
            form={form}
            id="frmRefundRequest"
            // labelCol={{ span: 24 }}
            // wrapperCol={{ span: 24 }}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="refundRequestForm"
          >
            <Form.Item
              name="product"
              label="Produto"
              className="fixedFormItem"
              rules={[
                {
                  required: true,
                  message: "Campo de preenchimento obrigatório.",
                },
              ]}
              hasFeedback
            >
              <Select onChange={(e) => setSelectedProduct(e)}>
                {sales.map((sale) => (
                  <Option
                    key={sale._id}
                    value={cannotBeRefunded(sale) ? null : sale._id}
                    disabled={cannotBeRefunded(sale)}
                  >
                    {sale.product_name} <small style={{ color: "#72849a" }}>({formatCurrency(sale.total)})</small>{" "}
                    <RefundInfo data={sale} />
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="reason"
              label="Motivo"
              className="fixedFormItem"
              rules={[
                {
                  required: true,
                  message: "Campo de preenchimento obrigatório.",
                },
              ]}
              hasFeedback
            >
              <Select
                onChange={(e) => {
                  setSelectedReasonItem(refundReasons?.find((item) => item?.id === e));
                  setSelectedReason(e);
                }}
                disabled={canRefund === false}
              >
                {refundReasons.map((reason) => (
                  <Option key={reason.id} value={reason.id}>
                    {reason.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {selectedReasonItem?.help !== undefined && (
              <Form.Item>
                <Alert message={selectedReasonItem.help} type="info" showIcon />
              </Form.Item>
            )}
            {selectedReasonItem?.ask_details !== undefined && (
              <Form.Item name="details" label="Detalhes" className="fixedFormItem">
                <Input.TextArea
                  rows={4}
                  placeholder="Explica mais detalhadamente o teu motivo."
                  disabled={canRefund === false}
                />
              </Form.Item>
            )}

            <Form.Item
              name="confirm"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  transform: (value) => value || undefined, // Those two lines
                  type: "boolean", // Do the magic
                  message: "Confirmação é obrigatória.",
                },
              ]}
              hasFeedback
            >
              <Checkbox onChange={(e) => setRefundConfirmed(e.target.checked)} disabled={canRefund === false}>
                Confirmo que pretendo o reembolso.
              </Checkbox>
            </Form.Item>

            {refundConfirmed && (
              <>
                {/* <Form.Item name="refund_by" label="Efetuar reembolso via">
                  <Select onChange={(e) => setSelectedRefundBy(e)} defaultValue={"auto"}>
                    <Option value="auto">
                      <em>Automático</em>
                    </Option>
                    <Option
                      value="iban"
                      disabled={
                        sale?.payment_method?.toUpperCase() !== "MB" &&
                        sale?.payment_method?.toUpperCase() !== "MBWAY"
                      }
                    >
                      Transferência bancária
                    </Option>
                    <Option
                      value="mbway"
                      disabled={
                        (sale?.payment_method?.toUpperCase() !== "MB" &&
                          sale?.payment_method.toUpperCase() !== "MBWAY") ||
                        sale?.total > 750
                      }
                    >
                      MBWAY
                    </Option>
                    <Option value="creditcard" disabled={sale?.payment_method.toUpperCase() !== "CREDITCARD"}>
                      Cartão de Crédito
                    </Option>
                    <Option value="paypal" disabled={sale?.payment_method.toUpperCase() !== "PAYPAL"}>
                      PayPal
                    </Option>
                  </Select>
                </Form.Item> */}
                {/* {selectedRefundBy === "iban" && ( */}
                <Form.Item label="IBAN para reembolso">
                  <Form.Item
                    noStyle
                    name="iban"
                    className="fixedFormItem"
                    rules={[
                      {
                        required: true,
                        message: "Campo de preenchimento obrigatório.",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      placeholder="PT50 0000 0000 0000 0000 0000 00"
                      onBlur={(e) => validateIBAN(e.target?.value)}
                      onChange={(e) => setIbanInfo(null)}
                      disabled={canRefund === false}
                    />
                  </Form.Item>
                  <IBANInfo />
                </Form.Item>
                {/* <Form.Item name="iban" label="IBAN para reembolso" className="fixedFormItem">
                  <MaskedInput
                    // prefix={"PT50 "}

                    //  https://imask.js.org/guide.html#masked-pattern
                    mask="aa00 0000 0000 00000000000 00"
                   
                  />
                </Form.Item> */}
                <Form.Item
                  name="confirm_iban"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      transform: (value) => value || undefined, // Those two lines
                      type: "boolean", // Do the magic
                      message: "Confirmação é obrigatória.",
                    },
                  ]}
                  hasFeedback
                >
                  <Checkbox onChange={(e) => setIbanConfirmed(e.target.checked)} disabled={canRefund === false}>
                    Confirmo que o IBAN está correto.
                  </Checkbox>
                </Form.Item>

                {/* )} */}
                {/* {selectedRefundBy === "mbway" && (
                  <Form.Item name="mbway_phone" label="MBWAY para reembolso" className="fixedFormItem">
                    <Input placeholder="Nº de telemóvel MBWAY" />
                  </Form.Item>
                )}
                {selectedRefundBy === "paypal" && (
                  <Form.Item name="paypal_email" label="E-mail do PayPal para reembolso" className="fixedFormItem">
                    <Input placeholder="Endereço de email do PayPal" />
                  </Form.Item>
                )} */}
              </>
            )}

            <div>
              <small>
                <em>
                  Em caso de dúvida entra em contacto com o suporte através do endereço de e-mail:
                  suporte@salespark.io.
                </em>
                <br />
                <em>O país do IBAN deve ser o mesmo do país de origem indicado durante o processo de compra.</em>
                <br />
                <em>O reembolso pode em alguns casos ser feito para o mesmo método do pagamento (Ex. PayPal)</em>
                <br />
                <em>
                  O reembolso segue as regras indicadas no ponto 3 dos{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://salespark-io.freshdesk.com/support/solutions/articles/80001058932"
                  >
                    Termos de Compra
                  </a>
                </em>
              </small>
            </div>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default RefundRequest;
