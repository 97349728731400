import { Select, Tag } from "antd";
import Cookies from "universal-cookie";
import mb from "../assets/images/mb.2.png";
import mbway from "../assets/images/mbway.2.png";
import creditcard from "../assets/images/creditcard.2.png";
import paypal from "../assets/images/paypal.2.png";
import klarna from "../assets/images/klarna.png";
import moment from "moment-timezone";
const { Option } = Select;
// Array with all european countries
const europeanCountries = [
  "AT", // Austria
  "BE", // Belgium
  "BG", // Bulgaria
  "CY", // Cyprus
  "CZ", // Czech Republic
  "DE", // Germany
  "DK", // Denmark
  "EE", // Estonia
  "ES", // Spain
  "FI", // Finland
  "FR", // France
  "GR", // Greece
  "HR", // Croatia
  "HU", // Hungary
  "IE", // Ireland
  "IT", // Italy
  "LT", // Lithuania
  "LU", // Luxembourg
  "LV", // Latvia
  "MT", // Malta
  "NL", // Netherlands
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "SE", // Sweden
  "SI", // Slovenia
  "SK", // Slovakia
];

export const validateAllowedCountry = (allowed_countries, country) => {
  if (allowed_countries === undefined) return true;
  if (!country) return true;

  if (allowed_countries.includes("*worldwide*")) return true;

  if (allowed_countries.includes("*europe*") === true) {
    if (country !== undefined && country !== null) {
      return europeanCountries.includes(country);
    }
    return true;
  }

  if (allowed_countries.includes(country) === true) {
    return true;
  }

  return false;
};

export const saveAffiliateId = (affiliate_id, product_id, state, expiresDays) => {
  try {
    if (IsNullUndefinedEmpty(state.affiliate_id) === false) return;
    if (!affiliate_id || !product_id || !state) return;

    const item = `SalesPark-Checkout-v2-aff-${product_id}`;
    const cookies = new Cookies(null, { path: "/" });

    // Set expires base on expiresDays
    const expires = new Date();

    if (IsNullUndefinedEmpty(expiresDays)) expiresDays = -1;

    if (expiresDays === -1) {
      expires.setDate(expires.getDate() + 365); // 1 year
    } else {
      expires.setDate(expires.getDate() + expiresDays); // x days
    }

    cookies.set(item, affiliate_id, { path: "/", expires });

    // Error handling
  } catch (error) {
    console.error("Error set aff", error);
  }
};

export const getAffiliateId = (product_id) => {
  try {
    if (!product_id) return null;

    const item = `SalesPark-Checkout-v2-aff-${product_id}`;
    const cookies = new Cookies(null, { path: "/" });
    return cookies.get(item);

    // Error handling
  } catch (error) {
    return null;
  }
};

export const utmParams = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const utm_source = params.get("utm_source") || "";
  const utm_medium = params.get("utm_medium") || "";
  const utm_campaign = params.get("utm_campaign") || "";
  const utm_term = params.get("utm_term") || "";
  const utm_content = params.get("utm_content") || "";

  return {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  };
};

export const BuyerInvoiceInfo = (isWidget, data) => {
  if (isWidget) return <>Os dados de faturação serão solicitados por e-mail após o pagamento.</>;
  if (data?.is_external === true && data?.url !== undefined) {
    return <>Os dados de faturação serão solicitados por e-mail após o pagamento.</>;
  }
  return <>Os dados de faturação serão solicitados após o pagamento.</>;
};

export const getMethodImage = (method_id) => {
  switch (method_id) {
    case "mbway":
      return mbway;
    case "mb":
      return mb;
    case "creditcard":
      return creditcard;
    case "paypal":
      return paypal;
    case "klarna":
      return klarna;
    default:
      return "";
  }
};

export const paymentMethodsArray = [
  { method_id: "mbway", name: "MB WAY" },
  { method_id: "mb", name: "Multibanco" },
  { method_id: "creditcard", name: "Cartão Crédito" },
  { method_id: "klarna", name: "Klarna" },
  { method_id: "paypal", name: "PayPal" },
];
export const purchaseProductsTotal = (purchaseProducts, formatNumber = false) => {
  let total = 0;
  purchaseProducts.forEach((product) => {
    total += product.price;
  });
  return formatNumber ? formatCurrency(total) : total;
};

export const formatMBReference = (reference) => {
  try {
    return reference
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{3})/g, "$1 ")
      .trim();
  } catch (error) {
    return reference;
  }
};

export const formatCurrency = (value, currency = "€", decimals = 2) => {
  if (!value || value === undefined) value = 0;
  value = parseFloat(value);
  let r = value.toFixed(decimals);
  var parts = r.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  r = parts.join(".");

  if (!!currency) return `${r}${currency}`;
  return r;
};

export const isIntegerNumber = (value) => {
  if (IsNullUndefinedEmpty(value)) return false;

  if (isNaN(value)) {
    return false;
  }
  return Number.isInteger(parseInt(value));
};

export const NotNullUndefinedEmpty = (value) => {
  try {
    if (value === null || value === undefined || value === "") return false;
    return true;
  } catch (error) {
    return false;
  }
};

export const IsNullUndefinedEmpty = (value) => {
  try {
    if (value === null || value === undefined) return true;
    value = value?.toString()?.trim();
    if (value === "") return true;
    return false;

    //Error handling
  } catch (error) {
    return true;
  }
};

export const IsNullUndefined = (value) => {
  try {
    if (value === null || value === undefined) return true;
    return false;
  } catch (error) {
    return true;
  }
};

export const NotNullUndefined = (value) => {
  try {
    if (value === null || value === undefined) return false;
    return true;
  } catch (error) {
    return false;
  }
};

export const saveOnLocalStorage = (name, data, product_id = "") => {
  try {
    const item = `SalesPark-Checkout-v2-${name}${product_id !== "" ? `-${product_id}` : ""}`;
    const json = JSON.stringify(data);
    localStorage.setItem(item, json);
  } catch (error) {
    console.error("Error saving data on local storage", error);
  }
};

export const loadFromLocalStorage = (name, product_id = "") => {
  try {
    const item = `SalesPark-Checkout-v2-${name}${product_id !== "" ? `-${product_id}` : ""}`;
    const data = localStorage.getItem(item);
    return JSON.parse(data);
  } catch (error) {
    console.error("Error saving data on local storage", error);
    return undefined;
  }
};

export const loadLocalData = (state, productData) => {
  try {
    let _state = state;
    const _identification = loadFromLocalStorage("identification");

    const _price_option = loadFromLocalStorage("price_option", productData?.product_id);
    if (_price_option !== undefined) _state = { ..._state, price_option: _price_option };

    // Get affiliate_id from Cookies
    const _affiliate_id = getAffiliateId(productData?.product_id);
    if (IsNullUndefined(_affiliate_id) === false) _state = { ..._state, affiliate_id: _affiliate_id };

    const _payment_method = loadFromLocalStorage("payment_method", productData?.product_id);
    if (_payment_method !== undefined) _state = { ..._state, payment_method: _payment_method };

    const qs_name = new URLSearchParams(window.location.search).get("name");
    const qs_email = new URLSearchParams(window.location.search).get("email");
    const qs_phone = new URLSearchParams(window.location.search).get("phone");

    // Overrides localStorage data with querystring data
    let identification = _identification;
    if (qs_name !== null) identification.name = qs_name;
    if (qs_email !== null) identification.email = qs_email;
    if (qs_phone !== null) identification.phone = qs_phone;
    if (_identification !== undefined) _state = { ..._state, identification: _identification };

    return _state;
  } catch (error) {
    return null;
  }
};

const parseStoreValue = (key, value) => {
  let ws = window.localStorage;
  let prefix = "SalesPark-Checkout-v2-";
  if (value === "INT") {
    return parseInteger(ws.getItem(prefix + key));
  }
  if (value === "OBJ") {
    return parseObj(ws.getItem(prefix + key));
  }
  if (value === "BOOL") {
    return parseBoolean(ws.getItem(prefix + key));
  }
  return ws.getItem(prefix + key);
};

export const store = (action, key, value) => {
  let ws = window.localStorage;

  let prefix = "SalesPark-Checkout-v2-";
  switch (action) {
    case "GET":
      return parseStoreValue(key, value); //value === "INT" ? parseInteger(ws.getItem(prefix + key)) : ws.getItem(prefix + key);

    case "SET":
      return ws.setItem(prefix + key, value);

    case "DELETE":
      return ws.removeItem(prefix + key);

    case "CLEAR":
      return ws.clear();
    default:
      return;
  }
};

export const parseInteger = (i) => {
  try {
    if (isNaN(i) || i === null) return 0;
    return parseInt(i);
  } catch (error) {
    return 0;
  }
};

export const parseBoolean = (i) => {
  try {
    switch (i?.toLowerCase()?.trim()) {
      case "true":
      case "yes":
      case "1":
        return true;

      case "false":
      case "no":
      case "0":
      case null:
      case undefined:
        return false;

      default:
        return JSON.parse(i);
    }
  } catch (error) {
    return false;
  }
};

export const parseObj = (i) => {
  try {
    return JSON.parse(i);
  } catch (error) {
    return isNaN;
  }
};

export const createPricingOptions = (offers) => {
  if (IsNullUndefinedEmpty(offers)) return <></>;
  return offers.map((offer) => {
    return (
      <Option key={offer.offer_id} value={offer.offer_id}>
        {_getPriceOption(offer)}
      </Option>
    );
  });
};

export const _getPriceOption = (offer) => {
  if (offer.n_payments === 1) {
    return (
      <>
        Pronto Pagamento -{" "}
        <Tag color="default">
          <strong>{formatCurrency(offer.price)}</strong>
        </Tag>
      </>
    );
  }
  if (offer.n_payments > 1 && offer.price_method === "installments") {
    return (
      <>
        Pagamento em Prestações -{" "}
        <Tag color="default">
          <strong>
            {offer.n_payments} x {formatCurrency(offer.price)}
          </strong>
        </Tag>
      </>
    );
  }
  if (offer.price_method === "recurrence") {
    return (
      <>
        Subscrição {offer?.payments_frequency === "month" ? "Mensal" : "Anual"}{" "}
        {offer.recurrence_billing_method === "limited" ? (
          <Tag color="default">
            <strong>
              {offer.recurrence_max_payments} x {formatCurrency(offer.price)}
            </strong>
          </Tag>
        ) : (
          <Tag color="default">
            <strong>{formatCurrency(offer.price)}</strong>
          </Tag>
        )}
      </>
    );
  }
};

export const createPurchaseProductsList = (mainProduct, stateData) => {
  try {
    let output = [];

    if (IsNullUndefinedEmpty(mainProduct)) return output;

    const qty = stateData.qty || 1;

    output.push({
      format: mainProduct.format,
      price_method: stateData.price_option.price_method,
      n_payments: stateData.price_option.n_payments,
      product_id: mainProduct.product_id,
      offer_id: stateData.price_option.offer_id,
      name: mainProduct.information.name,
      price: stateData.price_option.price,
      full_price: stateData.price_option.full_price,
      price_details: stateData.price_option.price_details,
      payments_frequency: stateData.price_option.payments_frequency,
      payments_interval: stateData.price_option.payments_interval,
      recurrence_max_payments: stateData.price_option.recurrence_max_payments,
      recurrence_billing_method: stateData.price_option.recurrence_billing_method,
      qty: qty,
      type: "main",
    });

    if (IsNullUndefinedEmpty(stateData.bumps)) return output;

    stateData.bumps.forEach((bump) => {
      const data = mainProduct.bumps.find(
        (i) => i.product.product_id === bump.product_id && i.product.offer_id === bump.offer_id
      );

      output.push({
        n_payments: data.product.n_payments,
        product_id: data.product.product_id,
        offer_id: data.product.offer_id,
        name: data.product.name,
        price: data.product.price,
        price_details: data.product.price_details,
        type: "bump",
      });
    });
    return output;
  } catch (error) {
    return [];
  }
};

export const purchaseProductsTotalFloat = (purchaseProducts) => {
  let total = 0;
  purchaseProducts.forEach((purchaseProduct) => {
    const qty = purchaseProduct.qty || 1;
    total += purchaseProduct.price * qty;
  });
  return total;
};

// export const validatePaymentAction = (data) => {
//   const totalAmount = purchaseProductsTotalFloat(data.purchase_products);
//   console.log("totalAmount", totalAmount);
//   if (data.payment_method.method_id === "mbway" && totalAmount > 750) {
//     return false;
//   }
//   return true;
// };

export const mbwayAboveLimit = (data) => {
  const totalAmount = sumPurchaseProdutcs(data.purchase_products);

  if (data?.payment_method?.method_id === "mbway" && totalAmount.price > 750) {
    return true;
  }
  return false;
};

export const allowKlarnaMethod = (data) => {
  const totalAmount = sumPurchaseProdutcs(data.purchase_products);

  if (totalAmount.price < 50 || totalAmount.price > 1000) {
    return false;
  }
  return true;
};

export const haveInstallments = (purchaseProducts) => {
  let _haveInstallments = false;
  purchaseProducts.forEach((purchaseProduct) => {
    if (purchaseProduct.n_payments > 1) _haveInstallments = true;
    if (purchaseProduct.price_method === "recurrence") _haveInstallments = true;
    if (purchaseProduct.price_method === "installments") _haveInstallments = true;
  });
  return _haveInstallments;
};

export const paymentMethodValid = (data, method_id = undefined) => {
  if (method_id === undefined) {
    if (data?.payment_method?.method_id === undefined) {
      return { status: true, data: null };
    }
    method_id = data.payment_method.method_id;
  }
  if (method_id === "mbway" && mbwayAboveLimit(data)) {
    return { status: false, data: "O valor máximo para pagamento via MB WAY é de 750€." };
  }
  if (method_id === "paypal" && haveInstallments(data.purchase_products)) {
    return { status: false, data: "Temporáriamente indisponível para pagamentos em prestações" };
  }

  return { status: true, data: "" };
};

export const refundAllowed = (salesData) => {
  if (salesData?.refund_end_date) {
    return moment.utc(salesData?.refund_end_date).local().endOf("day").isAfter(moment());
  } else if (salesData?.paid_date) {
    return moment.utc(salesData?.paid_date).add(15, "days").endOf("day").local().isAfter(moment());
  } else {
    return false;
  }
};

export const cancelFuturePaymentsAllowed = (salesData) => {
  // const isRecurrence = salesData?.is_recurrence;

  const recurrenceProducts = salesData?.products.filter((product) => product.price_method === "recurrence");
  if (recurrenceProducts.length === 0) return false;

  // if (!isRecurrence) return false;

  // Check if refund date is before now date
  if (salesData?.refund_end_date) {
    return moment.utc(salesData?.refund_end_date).local().endOf("day").isBefore(moment());
  }
  return false;
};

export const sumPurchaseProdutcs = (purchase_products, onlyMainProduct = false) => {
  let total = 0;
  let total_wo_vat = 0;
  let total_vat = 0;
  let discount = 0;
  let full_price = 0;

  for (const product of purchase_products) {
    if (onlyMainProduct === true && product.type !== "main") {
      continue;
    }
    const qty = product.qty || 1;
    total += product.price;
    if (product.price < product.full_price) {
      discount = product.full_price - product.price;
      full_price = product.full_price;
    }

    total_wo_vat += product.price_details.price_wo_vat;
    total_vat += product.price_details.vat_value;

    total = total * qty;
    total_wo_vat = total_wo_vat * qty;
    total_vat = total_vat * qty;
    discount = discount * qty;
    full_price = full_price * qty;
  }

  return {
    price: total,
    price_wo_vat: total_wo_vat,
    vat_value: total_vat,
    discount: discount,
    full_price: full_price,
  };
};
