import React, { useState } from "react";

import {
  FileDoneOutlined,
  CloudDownloadOutlined,
  LoadingOutlined,
  LinkOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Card, Tooltip, Button, Divider, Alert, notification, Modal } from "antd";
import { product_download_pdf_post, product_download_file_post } from "../../../api/product-download";
import Support from "../../../Components/Common/ProductContentSupport";
const detectMob = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

const ProductInternal = ({ item, index, length, purchase_id, product_id, token }) => {
  const [isLoading, setIsLoading] = useState(false);

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const viewOrDownloadFile = async (key, data) => {
    try {
      // ckeck if key ends with .pdf
      if (key.endsWith(".pdf")) {
        downloadPDF(key, data);
      } else {
        downloadFile(key, data);
      }
    } catch (error) {}
  };

  const downloadPDF = async (key, data) => {
    setIsLoading(true);
    product_download_pdf_post(key, data)
      .then((response) => {
        //window.open(response.data.url, "_blank");

        if (detectMob()) {
          window.location.assign(response.data.url);
        } else {
          var a = document.createElement("a");
          a.href = response.data.url;
          a.setAttribute("target", "_blank");
          a.click();
        }

        // if (!isDownload) {
        //   const blobObj = new Blob([response.data], { type: "application/pdf" });
        //   const url = window.URL.createObjectURL(blobObj);
        //   window.open(url);
        //   setIsLoading(false);
        // }
        // if (isDownload) {
        //   let base64String;
        //   let reader = new FileReader();
        //   reader.readAsDataURL(response.data);
        //   reader.onloadend = () => {
        //     base64String = reader.result;
        //     const pdfString = base64String.substr(base64String.indexOf(",") + 1);
        //     let a = window.document.createElement("a");
        //     a.href = "data:application/octet-stream;base64," + pdfString;
        //     a.download = `${key}.pdf`;
        //     a.click();
        //   };
        //   setIsLoading(false);
        // }
        setIsLoading(false);
      })
      .catch((err) => {
        notification.error({
          message: "Oops!",
          description: "Não foi possível carregar o conteúdo da compra. Tenta novamente mais tarde.",
        });
        setIsLoading(false);
        return null;
      });
  };

  const downloadFile = async (key, data) => {
    setIsLoading(true);
    product_download_file_post(key, data)
      .then((response) => {
        if (detectMob()) {
          window.location.assign(response.data.url);
        } else {
          var a = document.createElement("a");
          a.href = response.data.url;
          a.setAttribute("target", "_blank");
          a.click();
        }

        setIsLoading(false);
      })
      .catch((err) => {
        notification.error({
          message: "Oops!",
          description: "Não foi possível carregar o conteúdo da compra. Tenta novamente mais tarde.",
        });
        setIsLoading(false);
        return null;
      });
  };

  return (
    <>
      <Card
        size="small"
        type="inner"
        title={
          <>
            <strong>Produto: </strong>
            <span style={{ fontWeight: 400 }}>{item.product_name}</span>
          </>
        }
        style={{ marginBottom: 7 }}
      >
        {item.product_content?.map((content, index) => (
          <div key={index} style={{ display: "flex", justifyContent: "space-between" }}>
            {content?.itemtype !== "url" && (
              <>
                <div
                  className="products-content-item clickable_hover"
                  onClick={() => viewOrDownloadFile(content.key, { purchase_id, product_id, token })}
                >
                  <FileDoneOutlined />
                  <span style={{ marginLeft: 5 }}>{content?.name}</span>
                </div>
                <div>
                  <Tooltip title="Efetuar download" placement="topRight">
                    <Button
                      size="small"
                      type="primary"
                      className="mr-2"
                      icon={<CloudDownloadOutlined />}
                      onClick={() => viewOrDownloadFile(content.key, { purchase_id, product_id, token })}
                    />
                  </Tooltip>
                </div>
              </>
            )}
            {content?.itemtype === "url" && (
              <>
                <a href={content?.url} target="_blank" rel="noreferrer noopener">
                  <div className="products-content-item clickable_hover">
                    <LinkOutlined />
                    <span style={{ marginLeft: 5 }}>{content?.name}</span>
                  </div>
                </a>
                <div>
                  <Tooltip title="Abrir Link/Webiste" placement="topRight">
                    <a href={content?.url} target="_blank" rel="noreferrer noopener">
                      <Button
                        size="small"
                        type="primary"
                        className="mr-2"
                        icon={<GlobalOutlined />}
                        //onClick={() => openLink(content.url)}
                      />
                    </a>
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        ))}
        {item?.refunded === true && (
          <>
            <Alert
              message="Produto indisponível"
              description="Este produto foi reembolsado ou está em processo de reembolso."
              type="error"
              showIcon
            />
          </>
        )}
        <Support data={item.support} />
      </Card>
      {index !== length - 1 && <Divider dashed style={{ borderColor: "#838383" }} />}
      <Modal title="Está quase..." visible={isLoading} footer={null} closable={false}>
        <h3>
          <LoadingOutlined /> A preparar o ficheiro...
        </h3>
      </Modal>
    </>
  );
};

export default ProductInternal;
