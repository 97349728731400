import phoneValidation from "../config/phone_validation.json";
import europe_codes from "../config/europe_codes";
import { IsNullUndefinedEmpty } from "./helper";

/****************************************************
 * ##: Validate Phone Number
 * @param {String} phoneNumber - Phone number
 * @param {Object} phoneNumberData - Phone number data
 * @param {Function} setPhoneNumberData - Set phone number data
 * @param {Boolean} required - Required field
 * Resources:
 * https://github.com/mnestorov/regex-patterns?tab=readme-ov-file#portugal
 * History:
 * 14-10-2024: Created
 * 16-10-2024: Updated
 * 19-10-2024: Bug fix (validation when phone number is empty) and added required check
 ****************************************************/
export const validatePhoneNumber = (phoneNumber, phoneNumberData, setPhoneNumberData, required = false) => {
  try {
    // If phoneNumberData is null or undefined and not required then return true (phone valid)
    if (IsNullUndefinedEmpty(phoneNumberData) && required === false) {
      return { t: 1, isValid: true, phone: phoneNumber };
    }

    // If phoneNumber is null or undefined and not required then return true (phone valid)
    if (IsNullUndefinedEmpty(phoneNumber) && required === false) {
      return { t: 2, isValid: true, phone: phoneNumber };
    }
    let country = null;

    // Get country from data object (if exists)
    if (phoneNumberData?.data) {
      country = phoneNumberData?.data?.country;
    }

    // If country is null or undefined, then try to guess the country
    // from europe_codes object from dial_code. (use start of phone number)
    // Loop through europe_codes object
    if (!country) {
      for (const code of europe_codes) {
        // Check if phone number starts with dial code
        if (phoneNumber.startsWith(code.dial_code)) {
          // Set country object
          country = {
            dialCode: code.dial_code.replace("+", ""),
            iso2: code.code.toLowerCase(),
            name: code.name,
          };
          break;
        }
      }
    }

    // Get country code from data object
    const countryCode = country?.iso2?.toLowerCase();

    // If country code is null or undefined and not required, then return true (phone valid)
    if (!countryCode && required === false) return { t: 3, isValid: true, phone: phoneNumber };

    // Create local phone number (remove country code)
    const localPhoneNumber = phoneNumber.replace(/\+/g, "").substring(country.dialCode.length).trim();

    // Check if phone number is equal to country code (Theres no phone number)
    if (localPhoneNumber === "" && required === false) return { t: 4, isValid: true, phone: phoneNumber };

    // Check if phone number is empty and required is true
    if (localPhoneNumber === "" && required === true)
      return {
        t: 5,
        isValid: false,
        requiredWarning: true,
        title: "Campo obrigatório",
        message: "O número de telemóvel é de preenchimento obrigatório",
        phone: phoneNumber,
      };

    // Get country regex from phoneValidation object
    const countryRegex = phoneValidation[countryCode];

    // INFO: Fallback to valid
    // If country regex is null or undefined, then return true (phone valid)
    // Could not find the country regex to validate the phone number
    // So, the phone number is valid by default
    if (!countryRegex) return { t: 6, isValid: true };

    // Create regex object
    const regex = new RegExp(countryRegex);

    // Add plus sign to the beginning of the phone number
    if (phoneNumber[0] !== "+") {
      phoneNumber = "+" + phoneNumber;
    }

    // Check if phone number is valid
    if (!regex.test(phoneNumber)) {
      // If phone number is invalid, then set error to true
      setPhoneNumberData({ ...phoneNumberData, error: true });
      return {
        t: 7,
        isValid: false,
        title: "Número de telemóvel inválido",
        message: "O número de telemóvel indicado não é válido.",
        phone: phoneNumber,
        local_phone: localPhoneNumber,
      };
    }

    // If phone number is valid, then return true
    return { t: 8, isValid: true, phone: phoneNumber, local_phone: localPhoneNumber };

    // Error handling
  } catch (error) {
    // Continue with the process dispite the error.
    // The goal is the purchase to be completed.
    return { t: -1, isValid: true, phone: phoneNumber, error: error };
  }
};
