import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/css/index.css";
//import "antd/dist/antd.css";
import "./assets/css/base.css";
import "./assets/css/orderBumpShared.css"; //TODO: Fix path
import "./assets/css/flag-sprite.css";

import * as Sentry from "@sentry/react";

ReactDOM.render(<App />, document.getElementById("root"));

try {
  const globallyKnownErrors = [
    "top.GLOBALS", // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "AbortError: The play() request was interrupted by a call to pause()",
    "Non-Error promise rejection captured with value: undefined",
  ];
  const networkErrors = [/Network error/, /^Network request failed$/];

  Sentry.init({
    dsn: "https://3616326ee28721bad878d0551a99759d@o4506348392284160.ingest.sentry.io/4506484023427072",
    release: "salespark-checkout@2.1.397",
    environment: process.env.REACT_APP_ENVIRONMENT,
    ignoreErrors: [
      ...globallyKnownErrors,
      ...networkErrors,
      [/^ResizeObserver loop limit exceeded$/, /^ResizeObserver loop completed with undelivered notifications$/],
      [/^Uncaught TypeError: Cannot read property 'getBoundingClientRect' of null$/],
      [/^Uncaught (in promise) TypeError: Cannot read property 'getBoundingClientRect' of null$/],
      [/^Uncaught (in promise) TypeError: Cannot read property 'getBoundingClientRect' of undefined$/],
      [/^Uncaught (in promise) TypeError: Cannot read property 'getBoundingClientRect' of undefined$/],
      [/^Uncaught (in promise) TypeError: Cannot read property 'getBoundingClientRect' of null$/],
    ],

    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/api\.salespark\.io/,
          /^https:\/\/beta-api\.salespark\.io/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} catch (error) {
  console.error("Error initializing Sentry", error);
}
