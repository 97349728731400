import React, { useState, useEffect } from "react";
import { Modal, Form, Select, Spin, Button } from "antd";

import { ArrowRightOutlined } from "@ant-design/icons";

const { Option } = Select;

const parsePaymentMethodNames = (method) => {
  switch (method?.toUpperCase()) {
    case "MBWAY":
      return "MB WAY";
    case "MB":
      return "Multibanco";
    case "CREDITCARD":
      return "Cartão de Crédito";
    case "PAYPAL":
      return "PayPal";
    default:
      return method;
  }
};

const PaymentModal = ({ visible, close, data, installment, activeOrder, onSetPaymentMethod }) => {
  const [form] = Form.useForm();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
    if (visible === true && activeOrder) {
      setSelectedPaymentMethod(
        activeOrder?.method_details?.method?.toUpperCase() || activeOrder?.method?.toUpperCase()
      );
      form.setFieldsValue({
        payment_method: activeOrder?.method_details?.method?.toUpperCase() || activeOrder?.method?.toUpperCase(),
      });
    }
  }, [visible, data, activeOrder]);

  const ConfirmNewPaymentMethod = ({ paymentMethod }) => {
    return (
      <Button size="large" type="primary" onClick={() => onSetPaymentMethod(paymentMethod)}>
        Efetuar pagamento por {parsePaymentMethodNames(paymentMethod)} <ArrowRightOutlined />
      </Button>
    );
  };
  //console.log("activeOrder", activeOrder);
  return (
    <Modal
      title={`Pagamento da Prestação ${activeOrder?.installment_number}`}
      visible={visible}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          Cancelar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="Selecionar Método de Pagamento" name="payment_method">
          <Select size="large" style={{ width: "100%" }} onChange={(e) => setSelectedPaymentMethod(e)}>
            <Option value="MBWAY">MB WAY</Option>
            <Option value="MB">Multibanco</Option>
            <Option value="CREDITCARD">Cartão de Crédito</Option>
            {/* <Option value="PAYPAL">PayPal</Option> */}
          </Select>
        </Form.Item>
      </Form>
      <div style={{ textAlign: "center", paddingTop: 10, paddingBottom: 10 }}>
        <ConfirmNewPaymentMethod paymentMethod={selectedPaymentMethod} />
      </div>
    </Modal>
  );
};

export default PaymentModal;
