import React, { useState, useEffect } from "react";
import { Button, Result, Spin, Alert } from "antd";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import SelectPaymentMethodModal from "../../../Shared/SelectPaymentMethodModal";
import ErrorLoadingPayPal from "./ErrorLoadingPayPal";
import CheckingPayment from "./CheckingPayment";
import { order_post } from "api/orders";
import { payment_paypal_clientid_get, payment_paypal_create_intent_post } from "api/payments";
import { purchaseProductsTotalFloat } from "utils/helper";

import { utmParams } from "utils/helper";
const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = utmParams();

//https://paypal.github.io/react-paypal-js/?path=/docs/example-paypalbuttons--default
//https://codesandbox.io/s/sandpack-project-forked-j5pgvj?file=/App.js:1820-2066
// This values are the props in the UI
const currency = "EUR";
const style = { layout: "vertical" };

const ButtonWrapper = ({
  currency,
  showSpinner,
  amount,
  purchaseUnits,
  onCreateOrder,
  onInit,
  onError,
  onCancel,
  onApprove,
}) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, purchaseUnits, amount]);

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[purchaseUnits, amount, currency]}
        fundingSource={"paypal"}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [...purchaseUnits],

              shipping_type: "PICKUP", //TODO: Make sense? No  shipping
              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
            })
            .then((orderId) => {
              onCreateOrder(orderId);
              return orderId;
            });
        }}
        // createSubscription={function (data, actions) {
        //   return actions.subscription.create({
        //     plan_id: "P-17F061831E379891TMLMCNUA", // Creates the subscription
        //   });
        // }}
        onApprove={function (data, actions) {
          return actions.order.capture().then((details) => {
            onApprove(data, actions, details);
          });
        }}
        onCancel={function (data, actions) {
          onCancel(data, actions);
        }}
        onError={function (data) {
          onError(data);
        }}
        onInit={function (data) {
          onInit(data);
        }}
      />
    </>
  );
};

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/
const PayPal = ({ productData, data, actionHandler, step }) => {
  const [loading, setLoading] = useState(false);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState({ visible: false });
  const [paymentStatus, setPaymentStatus] = useState({});
  const [waitForPayment, setWaitForPayment] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paypalClientId, setPaypalClientId] = useState(undefined);
  const [errorFetching, setErrorFetching] = useState(false);
  const [paid, setPaid] = useState(false);
  //  const [orderId, setOrderId] = useState(undefined);

  const totalAmount = purchaseProductsTotalFloat(data.purchase_products);

  useEffect(() => {
    const status = data?.status?.action;
    setPaid(status === "paid");

    if (paypalClientId === undefined) getPayPalClientId();
  }, [data, paypalClientId]);

  const getPurchaseUnits = (purchase_id) => {
    if (data?.purchase_products === undefined) return;
    let total = data.purchase_products.reduce((acc, curr) => {
      return acc + curr.price;
    }, 0);

    total = total.toFixed(2);

    return [
      {
        custom_id: purchase_id,
        amount: {
          currency_code: "EUR",
          value: total,
          breakdown: {
            item_total: {
              currency_code: "EUR",
              value: total,
            },
          },
        },
        items: [...getItems()],
      },
    ];
  };

  const getItems = () => {
    const items = [];
    data.purchase_products.forEach((product) => {
      items.push({
        sku: product.product_id,
        name: ` x ${product.name}`,

        unit_amount: {
          currency_code: "EUR",
          value: product.price,
        },
        quantity: "1",
      });
    });
    return items;
  };

  const getPayPalClientId = () => {
    setLoading(true);
    setPaypalClientId(undefined);

    payment_paypal_clientid_get(productData.product_id)
      .then(async (res) => {
        //console.log("res", res);
        if (res.data.clientId !== undefined && res.data.clientId !== null) {
          const response = await createPayPalButton();
          if (response.status) {
            setErrorFetching(false);
            setPaypalClientId(res.data.clientId);
            hideLoading();
          } else {
            setLoading(false);
            setErrorFetching(true);
          }
        }
      })
      .catch((err) => {
        // console.log("err", err);
        setLoading(false);
        setErrorFetching(true);
      });
  };

  const hideLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const createPayPalButton = () => {
    if (data?.identification?.email === undefined || data?.identification?.email === null) {
      return;
    }

    return new Promise(async (resolve) => {
      setPaymentStatus({});
      setPaymentFailed(false);
      const payload = {
        buyer: data.identification,
        products: data.purchase_products,
        purchase_id: data.purchase_id,
        main_product: data.main_product,
        coupon: data.coupon,
        pathname: window.location.pathname,
        origin: window.location.origin,
        affiliate_id: data.affiliate_id,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
      };

      // Disable hability to change quantity
      actionHandler("qty_locked", true);

      // Create Order
      const order_response = await _createOrder({ ...payload, method: "paypal" });
      // Check if there is an error
      if (!order_response.status) {
        setErrorFetching(true);
        resolve({ status: false, data: {} });
        return;
      }

      resolve({ status: true, data: {} });
    });
  };

  const paymentCompleted = (orderID) => {
    actionHandler("update_status", {
      action: "wait-for-payment",
      data: {
        method_id: "PayPal",
        entity: "00000",
        reference: orderID,
      },
    });
    setWaitForPayment(true);
    //  console.log("paymentCompleted orderID", orderID);
  };

  const createPaymentIntent = (orderID) => {
    return new Promise(async (resolve) => {
      try {
        // Set orderId for later payment check
        //setOrderId(orderID);

        const payload = {
          buyer: data.identification,
          products: data.purchase_products,
          purchase_id: data.purchase_id,
          main_product: data.main_product,
          reference: orderID,
        };

        const create_intent_response = await _createPaymentIntent(payload);
        // Check if there is an error
        if (!create_intent_response.status) {
          setErrorFetching(true);
          resolve({ status: false, data: {} });
          return;
        }

        // Resolve promise
        resolve({ status: true, data: {} });

        // Error handling
      } catch (error) {
        //console.log("error", error);
        setErrorFetching(true);
        resolve({ status: false, data: error });
      }
    });
  };

  const _createOrder = (payload) => {
    return new Promise((resolve) => {
      try {
        order_post(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const _createPaymentIntent = (payload) => {
    return new Promise((resolve) => {
      try {
        payment_paypal_create_intent_post(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  return (
    <div className="paypal-container">
      <div className="payment-data-container">
        {paid === false && (
          <Spin spinning={loading} tip="A preparar pagamento por PayPal...">
            {loading && <div className="loading"></div>}

            {paypalClientId !== undefined && !errorFetching && paymentStatus?.status === undefined && (
              <div className={loading ? "loading-blur" : ""}>
                <div className="button-helper">Clica no botão abaixo para efetuares o pagamento via PayPal</div>
                <PayPalScriptProvider
                  options={{
                    "client-id": paypalClientId,
                    // vault: "true"
                    currency: "EUR",
                  }}
                >
                  <ButtonWrapper
                    amount={totalAmount}
                    currency={currency}
                    showSpinner={loading}
                    purchaseUnits={getPurchaseUnits(data?.purchase_id)}
                    onInit={async (data) => {
                      // Only dispatch createPaymentIntent when orderId is Created
                      //  console.log("ButtonWrapper onInit", data);
                      //  await createPaymentIntent(data.correlationID);
                      setLoading(false);
                    }}
                    onCreateOrder={async (orderId) => {}}
                    onError={(data) => {
                      //  alert("Error: " + JSON.stringify(data, 0, 2));
                      setPaymentStatus({ status: "error", data: data });
                      setPaymentFailed(true);
                    }}
                    onCancel={(data, actions) => {
                      //  alert("Cancel: " + JSON.stringify(data, 0, 2));
                      setPaymentStatus({ status: "cancel", data: data });
                      setPaymentFailed(true);
                    }}
                    onApprove={async (data, actions, details) => {
                      //  alert("Approve: " + JSON.stringify(data, 0, 2));
                      // const name = details.payer.name.given_name;
                      //console.log("onApprove orderID", data.orderID);
                      await createPaymentIntent(data.orderID);
                      paymentCompleted(data.orderID);
                      setPaymentStatus({ status: "approve", data, details });
                    }}
                  />
                </PayPalScriptProvider>
              </div>

              // <PayPalScriptProvider
              //   options={{
              //     "client-id": paypalClientId,
              //     fundingSource: "paypal",
              //     currency: "EUR",
              //     intent: "capture",
              //   }}
              // >
              //   <PayPalButtons style={{ layout: "horizontal" }} />
              // </PayPalScriptProvider>
            )}

            {paymentFailed && !loading && (
              <div className="payment-error">
                <div className="error">
                  {paymentStatus.status === "error" && (
                    <div className="alerts">
                      <Alert
                        message="Oops, pagamento não foi efetuado"
                        description="Ocorreu um erro ao efetuar o pagamento. Tenta novamente ou escolhe outro método de pagamento."
                        type="error"
                        showIcon
                      />
                    </div>
                  )}
                  {paymentStatus.status === "cancel" && (
                    <div className="alerts">
                      <Alert
                        message="Oops, pagamento foi cancelado"
                        description="Não foi possível concluir o pagamento por ter sido cancelado. Tenta novamente ou escolhe outro método de pagamento."
                        type="warning"
                        showIcon
                      />
                    </div>
                  )}
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" onClick={() => createPayPalButton()}>
                    Tentar novamente
                  </Button>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button type="link" onClick={() => setSelectPaymentMethod({ visible: true })}>
                    Selecionar outro método de pagamento
                  </Button>
                </div>
              </div>
            )}

            {errorFetching && !loading && (
              <ErrorLoadingPayPal
                getPayPalClientId={() => getPayPalClientId()}
                setSelectPaymentMethod={setSelectPaymentMethod}
                paypalClientId={paypalClientId}
              />
            )}

            {waitForPayment && !errorFetching && paymentStatus.status === "approve" && <CheckingPayment />}
          </Spin>
        )}
      </div>

      {paid === true && (
        <Result
          status="success"
          title="Pagamento Concluído"
          subTitle="Parabéns, a tua compra foi efetuada com sucesso!"
        />
      )}
      <SelectPaymentMethodModal
        data={data}
        actionHandler={actionHandler}
        visible={selectPaymentMethod.visible}
        close={() => setSelectPaymentMethod({ visible: false })}
      />
    </div>
  );
};

export default PayPal;
