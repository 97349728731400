import React, { useState } from "react";
import { Card, Typography, Button, Tooltip, Modal, Alert } from "antd";
import { RightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { locale } from "utils/moment_pt_PT";
import moment from "moment-timezone";
import Support from "../../../../../Components/Common/ProductContentSupport";
import EventDate from "../Components/EventDate";
import AddToCalendarLinks from "../Components/AddToCalendar/Links";
import EventCountdown from "../Components/EventCountdown";
import EventDetails from "../EventDetails";
const { Title } = Typography;
moment.locale("pt", locale);

const OnlineEvent = ({ item, purchase_id, product_id, index, isMobile, token, action }) => {
  const [showAddToCalendar, setShowAddToCalendar] = useState({ visible: false, data: null });
  const [detailsDrawer, setDetailsDrawer] = useState({ visible: action === "tickets" });
  const [instructionsVisible, setInstructionsVisible] = useState({ visible: false, name: "" });
  const [unsetAttendees, setUnsetAttendees] = useState(item?.unset_attendees || 0);
  let itemStyle = { display: "flex", justifyContent: "space-between", alignItems: "baseline" };

  if (isMobile) {
    itemStyle = { display: "grid" };
  }
  const numberOfTickets = item?.qty || 1;

  const CardTitle = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <strong>Evento Online: </strong>
          <span style={{ fontWeight: 400 }}>{item.product_name}</span>
          <span style={{ marginLeft: 5, fontWeight: 400 }}>
            ({numberOfTickets} {numberOfTickets === 1 ? "bilhete" : "bilhetes"})
          </span>
        </div>
        <EventCountdown startDate={item?.event?.start_date} />
      </div>
    );
  };

  return (
    <>
      <Card size="small" type="inner" title={<CardTitle />} style={{ marginBottom: 7 }}>
        <div style={itemStyle}>
          <div className="event-content-item">
            <div style={{ display: "grid", marginLeft: 5 }}>
              <EventDate
                isMobile={isMobile}
                item={item.event}
                productName={item.product_name}
                setShowAddToCalendar={setShowAddToCalendar}
              />
              {/* <LinkToEvent link={item?.event?.event_link} /> */}
              {/* <EventSchedule schedule_description={item.event?.schedule_description} /> */}
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {/* If booking has instructions */}
            {item?.event?.instructions && (
              <div className="mr-2">
                <Tooltip title="Ver instruções" placement="topRight">
                  <Button
                    type="default"
                    onClick={() =>
                      setInstructionsVisible({
                        visible: true,
                        name: item.product_name,
                      })
                    }
                  >
                    <InfoCircleOutlined /> Instruções
                  </Button>
                </Tooltip>
              </div>
            )}

            <div>
              {/* <Tooltip title="Ver Detalhes do Evento" placement="topRight"> */}
              <Button type="primary" className="mr-2" onClick={() => setDetailsDrawer({ visible: true })}>
                {numberOfTickets === 1 ? "Ver Detalhes do Bilhete" : "Ver Detalhes dos Bilhetes"} <RightOutlined />
              </Button>
              {/* </Tooltip> */}
              <Support data={item.support} />
            </div>
          </div>
        </div>
        {unsetAttendees > 0 && (
          <Alert
            style={{ marginTop: 10 }}
            message={
              <>
                <span className="mr-2">
                  Ainda falta definir {unsetAttendees} {unsetAttendees === 1 ? "participante" : "participantes"}.
                </span>
                <Button className="m-0 p-0" type="link" onClick={() => setDetailsDrawer({ visible: true })}>
                  Clica para definir {unsetAttendees === 1 ? " o participante" : "os participantes"}
                </Button>
              </>
            }
            type="warning"
            showIcon
          />
        )}
      </Card>

      <Modal
        closable={true}
        onCancel={() => setInstructionsVisible({ visible: false, name: "" })}
        title={`Instruções ${instructionsVisible.name}`}
        visible={instructionsVisible.visible}
        footer={
          <Button type="primary" onClick={() => setInstructionsVisible({ visible: false, name: "" })}>
            Fechar
          </Button>
        }
      >
        <p>{item?.event?.instructions?.split("\n").map((i, key) => (i ? <p key={key}>{i}</p> : null))}</p>
      </Modal>

      <Modal
        title="Adicionar ao Calendário"
        visible={showAddToCalendar.visible}
        onOk={() => setShowAddToCalendar({ visible: false, data: null })}
        onCancel={() => setShowAddToCalendar({ visible: false, data: null })}
        footer={[
          <Button type="primary" key="back" onClick={() => setShowAddToCalendar({ visible: false, data: null })}>
            Fechar
          </Button>,
        ]}
      >
        <Title level={4} type="secondary">
          Adicionar ao teu Calendário Preferido
        </Title>
        <AddToCalendarLinks showAddToCalendar={showAddToCalendar} />
      </Modal>
      <EventDetails
        setUnsetAttendees={setUnsetAttendees}
        visible={detailsDrawer.visible}
        close={() => setDetailsDrawer({ visible: false })}
        data={item}
        purchase_id={purchase_id}
        token={token}
      />
    </>
  );
};

export default OnlineEvent;
