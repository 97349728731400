import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../../Shared/CheckoutForm";
import { order_post } from "api/orders";
import { Spin, Alert, Button } from "antd";
import SelectPaymentMethodModal from "../../../Shared/SelectPaymentMethodModal";
import {
  payment_stripe_publishable_key_get,
  payment_stripe_create_intent_post,
  payment_stripe_intent_confirm_post,
  stripeV4CreatePaymentSession,
  confirmPaymentIntentV4,
} from "api/payments";

import { utmParams } from "utils/helper";
const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = utmParams();

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 **********************************************************************************************/
const Stripe = ({ productData, data, actionHandler, step, method }) => {
  //const [clientSecret, setClientSecret] = useState("");
  //const [intentId, setIntentId] = useState("");
  const [stripeData, setStripeData] = useState({ client_secret: "", intent_id: "", payment_unique_id: "" });
  const [publishableKey, setPublishableKey] = useState("");
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorFetching, setErrorFetching] = useState(false);
  const [selectPaymentMethod, setSelectPaymentMethod] = useState({ visible: false });
  const [stripeSessionId, setStripeSessionId] = useState(null);
  // const appearance = {
  //   theme: "stripe",
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };
  useEffect(() => {
    if (publishableKey === "") prepareCheckout();
  }, []);

  const prepareCheckout = async () => {
    if (data?.identification?.email === undefined || data?.identification?.email === null) {
      return;
    }

    setIsLoading(true);
    setErrorFetching(false);
    // Get Stripe publishable key
    const pub_key_response = await getPublishableKey(productData.product_id);
    // Check if there is an error
    if (!pub_key_response.status) {
      setErrorFetching(true);
      setIsLoading(false);
      return;
    }

    // Set publishable key and load Stripe
    setPublishableKey(pub_key_response.data.publishable_key);
    setStripePromise(() => loadStripe(pub_key_response.data.publishable_key));
    // stripePromise = loadStripe(pub_key_response.data.publishable_key);
    // Create payload
    const payload = {
      buyer: data.identification,
      products: data.purchase_products,
      purchase_id: data.purchase_id,
      main_product: data.main_product,
      coupon: data.coupon,
      pathname: window.location.pathname,
      origin: window.location.origin,
      affiliate_id: data.affiliate_id,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      payment_method: method,
    };

    // Create Order
    const order_response = await _createOrder(payload);
    // Check if there is an error
    if (!order_response.status) {
      setIsLoading(false);
      setErrorFetching(true);
      return;
    }

    // const create_intent_response = await _createPaymentIntent(payload);
    // // Check if there is an error
    // if (!create_intent_response.status) {
    //   setIsLoading(false);
    //   setErrorFetching(true);
    //   return;
    // }

    const createCheckoutSessionResponse = await createPaymentSession(payload);
    // Check if there is an error
    if (!createCheckoutSessionResponse.status) {
      setIsLoading(false);
      setErrorFetching(true);
      return;
    }

    setStripeSessionId(createCheckoutSessionResponse.data.session_id);

    setStripeData({
      session_id: createCheckoutSessionResponse.data.session_id,
      customer: createCheckoutSessionResponse.data.customer,
      success_url: createCheckoutSessionResponse.data.success_url,
      setup_intent: createCheckoutSessionResponse.data.setup_intent,
      client_secret: createCheckoutSessionResponse.data.client_secret,
      payment_unique_id: createCheckoutSessionResponse.data.payment_unique_id,
    });

    setIsLoading(false);
  };

  const _createOrder = (payload) => {
    return new Promise((resolve) => {
      try {
        order_post(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const _createPaymentIntent = (payload) => {
    return new Promise((resolve) => {
      try {
        payment_stripe_create_intent_post(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const createPaymentSession = (payload) => {
    return new Promise((resolve) => {
      try {
        // Disable hability to change quantity
        actionHandler("qty_locked", true);

        stripeV4CreatePaymentSession(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  // const _confirmPaymentIntent = (payload) => {
  //   return new Promise((resolve) => {
  //     try {
  //       payment_stripe_intent_confirm_post(payload)
  //         .then((res) => resolve({ status: true, data: res.data }))
  //         .catch((err) => resolve({ status: false, data: err }));
  //     } catch (error) {
  //       resolve({ status: false, data: error });
  //     }
  //   });
  // };

  const paymentCompleted = () => {
    actionHandler("update_status", {
      action: "wait-for-payment",
      data: {
        method_id: "CreditCard",
        entity: "00000",
        reference: stripeData.intent_id,
      },
    });
  };

  // const confirmPaymentIntent = (payload) => {
  //   return new Promise((resolve) => {
  //     try {
  //       confirmPaymentIntentV4(payload)
  //         .then((res) => resolve({ status: true, data: res.data }))
  //         .catch((err) => resolve({ status: false, data: err }));
  //     } catch (error) {
  //       resolve({ status: false, data: error });
  //     }
  //   });
  // };

  const paymentIntentConfirm = (payload) => {
    return new Promise((resolve) => {
      try {
        payment_stripe_intent_confirm_post(payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const getPublishableKey = (product_id) => {
    return new Promise((resolve) => {
      try {
        payment_stripe_publishable_key_get(product_id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  return (
    <>
      <div className="credit-card-container">
        <div className="payment-data-container">
          <Spin spinning={isLoading}>
            {errorFetching && (
              <div className="error">
                <Alert
                  message="Oops, ocorreu um erro ao inicializar o cartão de crédito."
                  description="Não foi possível apresentar o pedido para cartão de crédito. Tenta novamente dentro de alguns segundos."
                  type="error"
                  showIcon
                />
                <div style={{ textAlign: "center" }}>
                  <Button type="primary" onClick={() => prepareCheckout()}>
                    Tentar novamente
                  </Button>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button type="link" onClick={() => setSelectPaymentMethod({ visible: true })}>
                    Selecionar outro método de pagamento
                  </Button>
                </div>
              </div>
            )}
            {stripeData.client_secret !== "" && (
              <>
                <Elements
                  options={{
                    //   customerOptions: "",
                    appearance: { theme: "stripe" },
                    clientSecret: stripeData.client_secret,
                    locale: "pt",
                  }}
                  stripe={stripePromise}
                >
                  <CheckoutForm
                    data={data}
                    actionHandler={actionHandler}
                    stripeData={stripeData}
                    paymentCompleted={paymentCompleted}
                  />
                </Elements>
              </>
            )}
          </Spin>
        </div>
      </div>
      <SelectPaymentMethodModal
        data={data}
        actionHandler={actionHandler}
        visible={selectPaymentMethod.visible}
        close={() => setSelectPaymentMethod({ visible: false })}
      />
    </>
  );
};

export default Stripe;
