import { formatCurrency, IsNullUndefinedEmpty } from "utils/helper";

export const totalPayment = (selectedOffer) => formatCurrency(selectedOffer?.price * selectedOffer?.n_payments);

export const totalPaymentTooltip = (selectedOffer) => {
  if (selectedOffer.price_method === "installments") {
    return `Vais pagar ${formatCurrency(selectedOffer?.price)} agora, mais ${selectedOffer?.n_payments - 1} ${
      selectedOffer?.n_payments - 1 > 1 ? "prestações" : "prestação"
    } de ${formatCurrency(selectedOffer?.price)}. O valor total a pagar é de ${totalPayment(selectedOffer)}.`;
  }
  if (selectedOffer.price_method === "recurrence") {
    return `As recorrências terminam após um máximo de ${selectedOffer?.recurrence_max_payments} pagamentos.`;
  }
};

const productPrice = (product, mainProduct) => {
  if (IsNullUndefinedEmpty(product)) return <></>;

  let qty = 1;

  if (product?.product_id === mainProduct?.product_id) {
    qty = mainProduct?.qty || 1;
    const total = product?.price * qty;
    return formatCurrency(total);
  }

  return formatCurrency(product?.price);
};

export const showProductsListInfo = (product, mainProduct) => {
  if (IsNullUndefinedEmpty(product)) return <></>;

  if (product?.type === "bump") {
    return <div className="n_intallments">{productPrice(product, mainProduct)}</div>;
  }

  if (product?.price_method === "recurrence") return showProductsListRecurrenceInfo(product);
  if (product?.price_method === "installments") return showProductsListInstallmentsInfo(product);
  return <>{productPrice(product, mainProduct)}</>;
};

const showProductsListInstallmentsInfo = (product) => {
  if (IsNullUndefinedEmpty(product)) return <></>;
  if (product?.n_payments === 1) return <>{productPrice(product)}</>;

  return (
    <div className="n_intallments">
      {product.n_payments} x {productPrice(product)}
    </div>
  );
};

const showProductsListRecurrenceInfo = (product) => {
  let recurrenceFrequencySingular = "mês";

  if (product.payments_interval > 1) {
    if (product.payments_frequency) {
      recurrenceFrequencySingular = product.payments_frequency === "month" ? "meses" : "anos";
    }

    return (
      <div className="n_intallments " style={{ textAlign: "right" }}>
        <span style={{ lineHeight: "15px" }}>{productPrice(product)}</span>
        <br />
        <small style={{ lineHeight: "15px" }}>
          de {product.payments_interval} em {product.payments_interval} {recurrenceFrequencySingular}
        </small>
      </div>
    );
  }

  // If payments_interval is 1
  if (product.payments_frequency) {
    recurrenceFrequencySingular = product.payments_frequency === "month" ? "mês" : "ano";
  }

  return (
    <div className="n_intallments">
      {productPrice(product)} / {recurrenceFrequencySingular}
    </div>
  );
};

export const getOfferData = (offer_id, productData) =>
  productData?.offers.find((offer) => offer.offer_id === offer_id);

export const totalPriceInstallmentsInfo = (mainProduct, otherProducts) => {
  if (otherProducts?.length > 0) return "";
  if (mainProduct?.n_payments > 1) return `${mainProduct?.n_payments} x `;
  return "";
};
