import moment from "moment-timezone";
import { Button } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { google, outlook, office365, yahoo, ics } from "calendar-link";

const AddToCalendarButton = ({ item, productName, setShowAddToCalendar }) => {
  // Set event as an object

  // Diff between start and end date in hours
  const diffHours = moment(item.end_date)
    .set({ second: 0, millisecond: 0 })
    .diff(moment(item.start_date).set({ second: 0, millisecond: 0 }), "minutes");

  const event = {
    title: productName,
    description: item.description,
    start: moment(item.start_date).format("YYYY-MM-DD HH:mm Z"),
    duration: [diffHours, "minutes"],
  };

  return (
    <Button
      type="link"
      onClick={() =>
        setShowAddToCalendar({
          visible: true,
          data: {
            google: google(event),
            outlook: outlook(event),
            office365: office365(event),
            yahoo: yahoo(event),
            apple: ics(event),
            ics: ics(event),
          },
        })
      }
    >
      <CalendarOutlined /> Adicionar ao Calendário
    </Button>
  );
};

export default AddToCalendarButton;
