import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Spin, Skeleton, Card, Table, notification, Empty, Tooltip, Button } from "antd";
import ProductInternal from "./ProductInternal";
import ProductExternal from "./ProductExternal";
import CourseInternal from "./CourseInternal";
import CourseExternal from "./CourseExternal";
import Service from "./Service";
import OnlineEvent from "./Events/OnlineEvent";
import PhysicalEvent from "./Events/PhysicalEvent";
import { sales_product_content_get } from "../../../api/sales";
import useMediaQuery from "use-media-antd-query";

const ProductContent = ({ sale, token, action }) => {
  const [isLoading, setIsLoading] = useState(false);
  const colSize = useMediaQuery();
  //console.log("colSize", colSize);
  const mobileSizes = ["xs", "sm", "md"];
  const isMobileSize = mobileSizes.includes(colSize);
  const [productContent, setProductContent] = useState([]);
  useEffect(() => {
    if (sale?.billing_data_completed === true) {
      loadData(sale?.purchase_id, token);
    }
  }, []);

  const loadData = async (purchase_id, token) => {
    setIsLoading(true);

    const product_content_response = await dbGetProductContent(purchase_id, token);
    if (product_content_response.status) {
      setProductContent(product_content_response.data);
      //console.log("product_content_response", product_content_response.data);
    } else {
      notification.error({
        message: "Oops!",
        description: "Não foi possível carregar o conteúdo da compra. Tenta novamente mais tarde.",
      });
    }

    setIsLoading(false);
  };

  const dbGetProductContent = (purchase_id, token) => {
    return new Promise((resolve) => {
      try {
        sales_product_content_get(purchase_id, token)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="product-content-card">
        <Card>
          <div className="products-content">
            <h3 className="section-title">Conteúdo da Compra</h3>
            {isLoading && <Skeleton active />}
            {!isLoading && productContent.length === 0 && <Empty description="Não há conteúdo para esta compra." />}
            {!isLoading && productContent.length > 0 && (
              <>
                {productContent.map((item, index) => {
                  if (item?.format === "ebook" || item?.format === "digital") {
                    if (item?.external_product_content === false) {
                      return (
                        <ProductInternal
                          key={index}
                          item={item}
                          index={index}
                          length={productContent.length}
                          purchase_id={sale.purchase_id}
                          product_id={item.product_id}
                          token={token}
                          isMobile={isMobileSize}
                        />
                      );
                    }

                    if (item?.external_product_content === true)
                      return (
                        <ProductExternal
                          key={index}
                          item={item}
                          index={index}
                          length={productContent.length}
                          isMobile={isMobileSize}
                        />
                      );
                  }
                  if (item?.format === "course") {
                    if (item?.external_product_content === false)
                      return (
                        <CourseInternal
                          key={index}
                          item={item}
                          index={index}
                          length={productContent.length}
                          isMobile={isMobileSize}
                        />
                      );
                    if (item?.external_product_content === true)
                      return (
                        <CourseExternal
                          key={index}
                          item={item}
                          index={index}
                          length={productContent.length}
                          isMobile={isMobileSize}
                        />
                      );
                  }

                  if (item?.format === "service") {
                    return <Service key={index} item={item} index={index} isMobile={isMobileSize} />;
                  }

                  if (item?.format === "online-event") {
                    return (
                      <OnlineEvent
                        key={index}
                        item={item}
                        purchase_id={sale.purchase_id}
                        token={token}
                        index={index}
                        isMobile={isMobileSize}
                        action={action}
                      />
                    );
                  }

                  if (item?.format === "physical-event") {
                    return (
                      <PhysicalEvent
                        key={index}
                        item={item}
                        purchase_id={sale.purchase_id}
                        token={token}
                        index={index}
                        isMobile={isMobileSize}
                        action={action}
                      />
                    );
                  }

                  return <>Oops! Não foi possível determinar o tipo de conteúdo...</>;
                })}
                <div></div>
              </>
            )}
          </div>
        </Card>
      </div>
    </Spin>
  );
};

export default ProductContent;
