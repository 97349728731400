module.exports = [
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
    emoji: "🇦🇩",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
    emoji: "🇨🇾",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
    emoji: "🇨🇿",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
    emoji: "🇪🇪",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
    emoji: "🇫🇮",
  },

  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
    emoji: "🇮🇸",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
    emoji: "🇮🇪",
  },

  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
    emoji: "🇱🇹",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
    emoji: "🇱🇺",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
    emoji: "🇲🇹",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
    emoji: "🇲🇨",
  },

  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
    emoji: "🇵🇹",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
    emoji: "🇷🇴",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
    emoji: "🇪🇸",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
    emoji: "🇸🇪",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
    emoji: "🇨🇭",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
    emoji: "🇹🇷",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
    emoji: "🇬🇧",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
    emoji: "🇦🇹",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
    emoji: "🇧🇪",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
    emoji: "🇩🇰",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
    emoji: "🇫🇷",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
    emoji: "🇩🇪",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
    emoji: "🇬🇷",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
    emoji: "🇭🇺",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
    emoji: "🇮🇹",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
    emoji: "🇳🇱",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
    emoji: "🇳🇴",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
    emoji: "🇵🇱",
  },
];
