import React, { useState, useEffect } from "react";
import { Spin, Card, Divider, Button, Alert, Form, Input, Select, Modal, notification } from "antd";
//import Countdown from "react-countdown";
import { CheckCircleOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { vatid_get } from "../../api/validations";
import { IsNullUndefinedEmpty } from "../../utils/helper";
import { countries_fiscal_get } from "../../api/actions";
import postalCodes from "../../config/postal-codes.json";
import { DoubleDataConfirmationContent, NoVatIDContent, parseCompanyData } from "./BillingFormHelper";
import { PhoneInput, defaultCountries, parseCountry } from "react-international-phone";
import phoneCountriesAllowed from "../../config/phone_countries.json";
import { validatePhoneNumber } from "../../utils/validations";
import "react-international-phone/style.css";

const { Search } = Input;
const { Option } = Select;
const { confirm } = Modal;

/***********************************************************************************************
 ***********************************************************************************************
 ***********************************************************************************************
 * ##: APP
 ***********************************************************************************************
 ***********************************************************************************************
 ************************************************************************************************/
const BillingForm = ({ sale, buyer, billing, onConfirmBillingData }) => {
  const [form] = Form.useForm();
  const [phoneNumberData, setPhoneNumberData] = useState(null);
  const [isLoadingForm, setIsLoadingForm] = useState({ loading: true, message: "A carregar dados..." });
  const [alreadyAskFromAutoComplete, setAlreadyAskFromAutoComplete] = useState(false);
  const [isLoadingVatId, setIsLoadingVatId] = useState(false);
  const [errorLoadingVatIdData, setErrorLoadingVatIdData] = useState(false);
  //const [startCountDown, setStartCountDown] = useState(false);
  const [countries, setCountries] = useState([]);

  // const [timeLeft, setTimeLeft] = useState(900);
  const [data, setData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedRegion, setSelectedRegion] = useState();

  //const [postalCode, setPostalCode] = useState({});
  const regions = countries?.find((c) => c.value === selectedCountry)?.regions || [];
  const postalCodeFormat = postalCodes.find((p) => p.ISO === selectedCountry);

  useEffect(() => {
    init();
  }, []);
  // console.log("sale: ", sale);
  // console.log("buyer: ", buyer);
  // console.log("billing: ", billing);
  useEffect(() => {
    if (!data) return;
    if (!countries) return;
    if (countries.length === 0) return;

    const _postalCode = postalCodes.find((x) => x.ISO === data.country);

    // setPostalCode(_postalCode);
  }, [countries, data]);

  const allowedcCountriesList = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return phoneCountriesAllowed.includes(iso2);
  });

  const init = async () => {
    try {
      const response = await dbGetCountriesFiscal();

      if (response.status) {
        setCountries(response.data);
      } else {
        notification.error({
          message: "Erro ao carregar os países...",
          description: "Pode ser um erro temporário. Tenta novamente mais tarde.",
        });
        return;
      }

      if (!IsNullUndefinedEmpty(buyer)) {
        if (billing === null) {
          parseBuyerData(buyer);
          setSelectedCountry(buyer.country);
          setSelectedRegion(buyer.region);
          setPhoneNumberData({ phone: buyer.phone, error: false });
        } else {
          form.setFieldsValue(billing);
          setSelectedCountry(billing.country);
          setSelectedRegion(billing.region);
          setPhoneNumberData({ phone: billing.phone, error: false });
        }

        setIsLoadingForm({ loading: false, message: "" });
        //form.setFieldsValue({ country: "PT" });

        // if (sale?.billing_data_completed === false) {
        //   setStartCountDown(true);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dbGetCountriesFiscal = async () => {
    return new Promise((resolve) => {
      try {
        countries_fiscal_get()
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const askParseCompanyData = (data) => {
    if (!alreadyAskFromAutoComplete) {
      setAlreadyAskFromAutoComplete(true);
      confirm({
        title: "Preenchimento automático",
        content: `Foram encontrados dados com este número de contribuinte. Queres preencher automaticamente? Os dados atuais serão substituídos.`,
        okText: "Sim",
        okType: "danger",
        cancelText: "Não",
        keyboard: true,
        maskClosable: true,
        onOk() {
          parseCompanyData(form, data);
        },
        onCancel() {
          console.log("Boa escolha 😎");
        },
      });
    }
  };

  const parseBuyerData = (data) => {
    try {
      const _data = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        vatid: data.vatid,
        country: data.country,
        region: data.region,
      };
      setData(_data);
      form.setFieldsValue(_data);
      form.validateFields(["vatid"]);
      // form.setFieldsValue({ name: data.name });
      // form.setFieldsValue({ email: data.email });
      // form.setFieldsValue({ phone: data.phone });
      // form.setFieldsValue({ country: data.country });
      // form.setFieldsValue({ region: data.region });
    } catch (error) {}
  };

  const onFinishBilling = async (values) => {
    const vatid = values.vatid;

    values.country = selectedCountry;
    values.region = selectedRegion;

    const phoneValidation = validatePhoneNumber(phoneNumberData.phone, phoneNumberData, setPhoneNumberData);
    if (phoneValidation.isValid === false && phoneValidation.requiredWarning !== true) {
      Modal.warning({
        title: phoneValidation.title,
        content: phoneValidation.message,
        okText: "OK",
      });
      return;
    }

    if (errorLoadingVatIdData && !IsNullUndefinedEmpty(vatid)) {
      const e = await unconfirmedVatId();
      if (!e) return;
    }
    if (IsNullUndefinedEmpty(vatid)) {
      const r = await noVatId();
      if (!r) return;
    }
    const dc = await doubleDataConfirmation(values);
    if (dc) onConfirmBillingData(values);
  };

  const doubleDataConfirmation = () => {
    return new Promise((resolve) => {
      confirm({
        title: "Confirmas os dados introduzidos?",
        content: <DoubleDataConfirmationContent />,
        okText: "Sim, confirmo os dados",
        okType: "primary",
        cancelText: "Não",
        keyboard: true,
        maskClosable: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const unconfirmedVatId = () => {
    return new Promise((resolve) => {
      confirm({
        title: "Número de Contribuinte",
        content: "Não conseguimos validar o número de contribuinte. Continuar mesmo assim?",
        okText: "Sim",
        okType: "primary",
        cancelText: "Não",
        keyboard: true,
        maskClosable: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const noVatId = () => {
    return new Promise((resolve) => {
      confirm({
        title: "Número de Contribuinte",
        content: <NoVatIDContent />,
        okText: "Sim, confirmo",
        okType: "primary",
        cancelText: "Não",
        keyboard: true,
        maskClosable: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const validateVatId = async (vatid) => {
    return new Promise((resolve) => {
      setIsLoadingVatId(true);
      vatid_get(vatid)
        .then((res) => {
          resolve({ status: true, data: res.data });
          setErrorLoadingVatIdData(false);
        })
        .catch((err) => {
          resolve({ status: false, data: err });
          setErrorLoadingVatIdData(true);
        })
        .finally(() => {
          setIsLoadingVatId(false);
        });
    });
  };

  return (
    <>
      <Card className="billing-form-card" style={{ marginBottom: 35 }}>
        <div className="billing-info">
          <h3 className="section-title">Dados de Faturação</h3>
          <Divider dashed style={{ margin: 10 }} />

          <Spin spinning={isLoadingForm.loading} tip={isLoadingForm.message}>
            <Form
              className="billing-form"
              disabled={isLoadingForm.loading}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              layout="horizontal"
              form={form}
              onFinish={onFinishBilling}
            >
              <Form.Item
                name="vatid"
                label="Número de Contribuinte"
                rules={[
                  {
                    validator: async (_, value) => {
                      // If Country isnt Portugal, then dont validate VATID
                      if (data.country?.toUpperCase() !== "PT") return Promise.resolve();

                      if (value === undefined || value === "" || value === null) {
                        return Promise.resolve();
                      }

                      if (value.length > 0 && value.length < 9 && isNaN(value) === false) {
                        return Promise.reject("Número de contribuinte incompleto.");
                      }
                      if (value.length > 0 && value.length < 9 && isNaN(value) === true) {
                        return Promise.reject("Número de contribuinte inválido.");
                      }

                      const rv = await validateVatId(value, true);
                      if (rv.status) {
                        if (rv.data.status === false) {
                          return Promise.reject(rv.data.message);
                        } else {
                          if (rv.data.data !== null) askParseCompanyData(rv.data.data);
                          return Promise.resolve("Número de contribuinte válido!");
                        }
                      } else {
                        return Promise.reject("Não foi possível verificar o número de contribuinte.");
                      }
                    },
                  },
                  {
                    required: false,
                  },
                ]}
                hasFeedback
              >
                <Search
                  className="vatid"
                  placeholder="Número de Contribuinte"
                  loading={isLoadingVatId}
                  enterButton
                  onSearch={(e) => {
                    form.validateFields(["vatid"]);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="name"
                label="Nome ou Empresa"
                rules={[{ required: true, message: "O nome é de preenchimento obrigatório..." }]}
                hasFeedback
              >
                <Input placeholder="O teu nome ou o nome da Empresa" />
              </Form.Item>

              <Form.Item
                name="email"
                label="Endereço de e-mail"
                rules={[
                  {
                    required: true,

                    message: "O endereço de e-mail é de preenchimento obrigatório...",
                  },
                  {
                    type: "email",
                    message: "O endereço de e-mail é inválido...",
                  },
                ]}
                hasFeedback
              >
                <Input placeholder="O endereço de e-mail para envio da fatura" />
              </Form.Item>

              <Form.Item label="Número de telemóvel">
                <Form.Item
                  noStyle
                  name="phone"
                  rules={[
                    {
                      required: data?.customization?.phone_required === true,

                      message: "Este campo é de preenchimento obrigatório!",
                    },
                  ]}
                >
                  <PhoneInput
                    value={phoneNumberData?.phone}
                    defaultCountry="pt"
                    placeholder="Número de Telemóvel"
                    countries={allowedcCountriesList}
                    preferredCountries={["pt", "es", "fr", "ie", "gb"]}
                    disableDialCodeAndPrefix={true}
                    onChange={(phone, meta) => {
                      setPhoneNumberData({ phone, data: meta, error: false });
                    }}
                  />
                </Form.Item>
                {phoneNumberData?.error === true && (
                  <Alert
                    message="O número de telemóvel indicado não é válido."
                    type="error"
                    showIcon
                    style={{ marginTop: 10 }}
                  />
                )}
              </Form.Item>

              <Form.Item name="address" label="Morada" rules={[{ required: false }]} hasFeedback>
                <Input placeholder="Morada" />
              </Form.Item>

              <Form.Item name="city" label="Cidade" rules={[{ required: false }]} hasFeedback>
                <Input placeholder="Cidade" />
              </Form.Item>

              <Form.Item
                name="postal_code"
                label="Código Postal"
                // rules={[
                //   {
                //     validator: (_, value) => {
                //       if (value === undefined || value === "" || value === null) {
                //         return Promise.resolve();
                //       }

                //       if (/(\d{4}-\d{3})/.test(value)) {
                //         return Promise.resolve();
                //       } else {
                //         return Promise.reject("Código Postal inválido");
                //       }
                //     },
                //   },
                //   {
                //     required: false,
                //   },
                // ]}
                // hasFeedback
              >
                <Input
                  className="postal-code"
                  placeholder={postalCodeFormat ? postalCodeFormat?.Format?.replace(/N/gi, "0") : ""}
                />
              </Form.Item>

              <Form.Item
                name="country"
                label="País"
                rules={[{ required: true, message: "País é de preenchimento obrigatório." }]}
                hasFeedback
              >
                <Select disabled>
                  {countries?.map((country) => (
                    <Option key={country.value} value={country.value}>
                      {country.portugueseName} ({country.localName})
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {regions.length > 1 && (
                <Form.Item
                  name="region"
                  label="Região"
                  rules={[{ required: regions.length > 1, message: "Região é de preenchimento obrigatório." }]}
                  hasFeedback
                >
                  <Select disabled>
                    {regions.map((region) => (
                      <Option key={region.value} value={region.value}>
                        {region.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <div style={{ marginTop: 15, display: "flex", justifyContent: "right", marginBottom: 15 }}>
                <Form.Item>
                  <Button
                    disabled={isLoadingForm.loading}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="submit-button"
                    onClick={() => setAlreadyAskFromAutoComplete(true)}
                  >
                    Confirmar os dados <CheckCircleOutlined />
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Spin>
        </div>
      </Card>
    </>
  );
};

export default BillingForm;
