import React, { useState } from "react";
import { Avatar, Alert, Typography, Divider, Modal, Button, Drawer } from "antd";
import { UserOutlined, PhoneOutlined, WhatsAppOutlined, MailOutlined } from "@ant-design/icons";
import { IsNullUndefinedEmpty } from "utils/helper";
const { Text, Paragraph } = Typography;

const ProducerContacts = ({ producer }) => {
  try {
    if (!producer) return <></>;

    let supportEmail = producer?.email;

    if (IsNullUndefinedEmpty(supportEmail)) {
      return (
        <Alert
          message="Erro ao carregar dados do produtor"
          description="Por favor, tente novamente mais tarde"
          type="error"
          showIcon
        />
      );
    }

    console.log("Producer: ", producer);

    return (
      <>
        <div className="mb-2" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          {producer?.picture ? (
            <Avatar size={32} src={producer.picture} />
          ) : (
            <Avatar size={32} icon={<UserOutlined />} />
          )}
          <div>
            <h3 className="ml-2 mb-0">{producer?.name}</h3>
          </div>
        </div>

        <Paragraph
          style={{ marginBottom: 8 }}
          copyable={{ tooltips: ["Copiar endereço de e-mail", "Copiado"], text: supportEmail }}
        >
          <MailOutlined /> <strong>Endereço de e-mail: </strong> {supportEmail}
        </Paragraph>

        {producer?.support_phone?.length > 5 && (
          <div>
            <Paragraph
              style={{ marginBottom: 8 }}
              copyable={{ tooltips: ["Copiar número de telefone", "Copiado"], text: producer.support_phone }}
            >
              <PhoneOutlined /> <strong>Telefone: </strong>
              <a rel="noreferrer" target="_blank" href={`tel:${producer.support_phone}`}>
                {producer.support_phone.match(/.{1,3}/g).join(" ")}
              </a>
            </Paragraph>
          </div>
        )}

        {producer?.support_whatsapp?.length > 5 && (
          <div>
            <Paragraph
              style={{ marginBottom: 8 }}
              copyable={{ tooltips: ["Copiar número de WhatsApp", "Copiado"], text: producer.support_whatsapp }}
            >
              <WhatsAppOutlined /> <strong>WhatsApp: </strong>
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://wa.me/${producer.support_whatsapp?.replace("+", "")}`}
              >
                {producer.support_whatsapp.match(/.{1,3}/g).join(" ")}
              </a>
            </Paragraph>
          </div>
        )}
      </>
    );

    // Error handling
  } catch (error) {
    console.error("Error in ProducerContacts: ", error);
    return (
      <Alert
        message="Erro ao carregar dados do produtor"
        description="Por favor, tente novamente mais tarde"
        type="error"
        showIcon
      />
    );
  }
};

const Producer = ({ data, isMobile }) => {
  const [showProducerModal, setShowProducerModal] = useState(false);

  const Initials = ({ name }) => {
    name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const short_name = name.replace(/([a-z]+) .* ([a-z]+)/i, "$1 $2");
    return short_name.match(/\b\w/g).join("");
  };

  return (
    <>
      <Divider style={{ marginTop: 15, marginBottom: 15, paddingTop: isMobile ? 15 : 0 }} dashed />
      <div className="producer-container">
        <Avatar size="small" style={{ backgroundColor: "#87d068" }}>
          <Initials name={data?.producer?.name} />
        </Avatar>

        <div className="producer">
          <div>
            <Text className="producer-name">
              <strong>Produtor:</strong>{" "}
              <span onClick={() => setShowProducerModal(true)} className="clickable_hover">
                {data?.producer?.name}
              </span>
            </Text>
          </div>
        </div>
      </div>
      {!isMobile && (
        <Modal
          visible={showProducerModal}
          title="Contacto do Produtor"
          onCancel={() => setShowProducerModal(false)}
          footer={[
            <Button key="back" onClick={() => setShowProducerModal(false)}>
              Fechar
            </Button>,
          ]}
        >
          <ProducerContacts producer={data?.producer} />
        </Modal>
      )}
      {isMobile && (
        <Drawer
          title="Contacto do Produtor"
          placement="bottom"
          closable={true}
          onClose={() => setShowProducerModal(false)}
          visible={showProducerModal}
          footer={
            <div style={{ textAlign: "center" }}>
              <Button type="primary" key="back" onClick={() => setShowProducerModal(false)}>
                Fechar
              </Button>
              ,
            </div>
          }
        >
          <ProducerContacts producer={data?.producer} />
        </Drawer>
      )}
    </>
  );
};

export default Producer;
