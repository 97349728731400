import React from "react";
import { Typography } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";

const { Text } = Typography;

const Address = ({ event, simple }) => {
  if (!event) return null;
  const city = event?.city !== undefined ? `${event.city}` : "";
  const postal_code = event?.postal_code !== undefined ? `, ${event.postal_code}` : "";
  const eventLocal = `${event.address}${postal_code} ${city}` || "";

  if (simple === true) {
    return eventLocal;
  }

  return (
    <Text>
      <strong>
        <EnvironmentOutlined /> Local do Evento:
      </strong>{" "}
      {eventLocal}
    </Text>
  );
};

export default Address;
