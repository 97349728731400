import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IndexPage from "./pages";
import BillingInformation from "./pages/billing-information";
import Authentication from "./pages/authentication";
import ConfirmPayment from "./pages/confirm-payment";
import ProductNotFound from "./Components/Shared/ProductNotFound";
import InvalidCountry from "./Components/Shared/InvalidCountry";
import ProductSalesClosed from "./Components/Shared/ProductSalesClosed";
import PurchaseCompleted from "pages/purchase-completed";
import UserBlocked from "Components/Shared/UserBlocked";
import InstallmentsPayment from "pages/installments-payment";
import AttendeeTicket from "pages/tickets";
import { ConfigProvider } from "antd";
import antdPtPT from "antd/es/locale/pt_PT";
function App() {
  return (
    <ConfigProvider
      locale={antdPtPT}
      areaMapper={(area) => {
        return {
          ...area,
          emoji: <span className={`fp ${area.short.toLowerCase()}`} />,
        };
      }}
    >
      <Router>
        <Routes>
          <Route exact path="/authentication" element={<Authentication />} />
          <Route exact path="/:product_id/purchase-completed" element={<PurchaseCompleted />} />
          <Route exact path="/billing/:purchase_id" element={<BillingInformation />} />
          <Route exact path="/product-sales-closed" element={<ProductSalesClosed />} />
          <Route exact path="/product-not-found" element={<ProductNotFound />} />
          <Route exact path="/invalid-country" element={<InvalidCountry />} />
          <Route exact path="/something-went-wrong" element={<UserBlocked />} />
          <Route exact path="/installments-payment/:purchase_id" element={<InstallmentsPayment />} />
          <Route exact path="/:product_id" element={<IndexPage />} />
          <Route exact path="/ticket/:ticket_code" element={<AttendeeTicket />} />
          <Route
            exact
            path="/:product_id/confirm-payment/:purchase_id/:payment_unique_id?"
            element={<ConfirmPayment />}
          />
          <Route exact path="/" element={<IndexPage />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
