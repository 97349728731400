import React from "react";
import { Result } from "antd";
import unhappy from "assets/images/unhappy.svg";

const ProductSalesClosed = ({ message }) => {
  let defaultMessage =
    "Já terminou o período de venda deste produto. Em caso de dúvidas, entra em contato com o produtor.";

  if (message !== undefined) {
    defaultMessage = message;
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", paddingTop: 25 }}>
        <img className="img-fluid" src={"/img/logo-white.png"} alt="" />
      </div>
      <Result
        icon={<img style={{ width: "80%", maxWidth: "300px" }} src={unhappy} alt="unhappy" />}
        title="Este produto já não está disponível para venda."
        subTitle={defaultMessage}
      />
    </>
  );
};

export default ProductSalesClosed;
