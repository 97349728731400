import React, { memo } from "react";

import { IsNullUndefinedEmpty } from "../../../utils/helper";
import OrderBump from "../../Shared/OrderBump";

const OrderBumpComponent = memo(
  ({ step, orderBumps, data, actionHandler }) => {
    if (IsNullUndefinedEmpty(orderBumps)) return <></>;

    return orderBumps.map((orderBump) => (
      <div key={orderBump.product.product_id}>
        <OrderBump step={step} orderBump={orderBump} data={data} actionHandler={actionHandler} />
      </div>
    ));
  },
  (prevProps, nextProps) => {
    return prevProps.data === nextProps.data;
  }
);

const OrderBumps = memo(
  ({ productData, data, actionHandler, step }) => {
    return (
      <OrderBumpComponent orderBumps={productData.bumps} data={data} step={step} actionHandler={actionHandler} />
    );
  },
  (prevProps, nextProps) => {
    return prevProps.data?.purchase_products?.length === nextProps.data?.purchase_products?.length;
  }
);

export default OrderBumps;
